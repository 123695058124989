import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import http from "../utils/Api"
import { Link } from "react-router-dom";

const GameAnalytics = ({ game }) => {
  const [chartOption, setChartOption] = useState("hours");
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playedData, setPlayedData] = useState({});
  const [pdata, showPdata] = useState(false);
  const [showChart, setShowChart] = useState(true);
  const [subscription, setSubscription] = useState({})
  const [cpmData, setCpmData] = useState({})
  const [showCpData, setShowCpData] = useState(false)
  const [similarGames, setSimilarGames] = useState([])
  const [showSimilar, setShowSimilar] = useState(false)
  const [installsData, setInstallsData] = useState([]);

  const token = localStorage.getItem("accessToken");
  console.log("GAME", game)
//   const fetchData = async (option) => {
//     setLoading(true);
    
    
//     try {
    
//       const apiUrl = `/api/admin/gameAllStatistics/${game.packageName}?state=${option}`;
//       const response = await http.get(apiUrl, http.generateConfig(token));
//       console.log("API Response:", response);

//       if (response?.game?.stateByDate) {
//         console.log("State by Date:", response.game.stateByDate);
//         setChartData(response.game.stateByDate);
//         if (option === "install") {
//           setInstallsData(response.game.stateByDate);
//         }
//       } else {
//         console.log("No state by date data available.");
//         setChartData([]);
//         if (option === "install") {
//           setInstallsData([]);
//         }
//       }
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//       setLoading(false);
//     }
//   };
const getGameSubscription = async () => {
    try {
      const res = await http.get(`/api/studio-subscriptionByGameId/${game.id}`);
      if (!!res?.result) {
        if (res.result !== null) {
          setSubscription(res.result);
        }
      } else {
        // toast.error(`Error ${res.message}`, {
        //   position: "top-right",
        // });
      }
    } catch (error) {
      // toast.error("Error fetching subscription data", {
      //   position: "top-right",
      // });
    }
  };

  const fetchData = async (option) => {
    setLoading(true);
    try {
      const apiUrl = `/api/admin/gameAllStatistics/${game}?state=${option}`;
      const response = await http.get(apiUrl, http.generateConfig(token));
      console.log("RESPONSE", response)
  
      setTimeout(() => {
        
        
        setChartData(
          response?.game?.stateByDate ? response?.game?.stateByDate : []
        );
        if (option === "install") {
          setInstallsData(response?.game?.stateByDate ? response?.game?.stateByDate : []);
        }
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  const getPlayedData = async () => {
    try {
      setLoading(true);
      showPdata(true);
      setShowCpData(false);

      const res = await http.get(
        `/api/admin/getGameDetailpackageName/${game}`, http.generateConfig(token)
      );
      if (res) {
        setPlayedData(res);
        setChartOption("");
        setLoading(false);
        setShowChart(false);
      }
    } catch (error) {
      console.error("Error fetching played data:", error);
    }
  };
  const getCPMData = async (price) => {
    try {
      setLoading(true);
      showPdata(false);
      setShowSimilar(false)
      setShowCpData(true);
      const res = await http.get(
        `api/admin/get_CPM_CPC_CPI/${game}?amountPaid=${price}`, http.generateConfig(token)
      );
      if (res) {
        setCpmData(res.result);
        setChartOption("");
        setLoading(false);
        setShowChart(false);
      }
    } catch (error) {
      console.error("Error fetching played data:", error);
    }
  }



  useEffect(() => {
    
    fetchData(chartOption);
    
  }, [chartOption, game?.packageName]);

  useEffect(() => {
    setChartOption("hours");
    setChartData([]);
    setInstallsData([]);
    fetchData("hours");
    

  }, [game?.packageName]);
  

   
 
  const options = {
    chart: {
      type: "line",
      height: 400,
    },
    xaxis: {
      type: "datetime",
    },
    legend: {
      show: false,
    },
  };
console.log("chardata", chartData)
  const series = [
    {
      name: chartOption === "hours" ? "Total Hours" :
        chartOption === "launch" ? "Total launches" :
          chartOption === "install" ? "likely Earnscape Driven" :
            chartOption === "click" ? "Total Click" :
              chartOption === "view" ? "Total Views" :
                chartOption === "DAUs" ? "Active Users" : 
                "",
                
      data: chartData.map((item) => {
       
        return chartOption === "hours"
          ? [new Date(item.date).getTime(), item.totalDuration]
          : chartOption === "launch"
            ? [new Date(item.date).getTime(), item.launch]
            : chartOption === "install"
              ? [new Date(item.date).getTime(), item.install]
              : chartOption === "click"
                ? [new Date(item.date).getTime(), item.click]
                : chartOption === "DAUs"
                    ? [new Date(item.date).getTime(), item.uniqueUsers]
                : chartOption === "view"
                ? [new Date(item.date).getTime(), item.view]
                  : [];

      }),
    },
    
   

    ...(chartOption === "install"
    ? [
        {
          name: "Total",
          data: chartData.map((item) => [
            new Date(item.date).getTime(),
            // item.installed + item.install,
            Number(item.installed) + Number(item.install)
          ]),
        },
        {
          name: "Earnscape Driven",
          data: chartData.map((item) => [
            new Date(item.date).getTime(),
            item.installed,
          ]),
        },
        {
          name: "First time installs",
          data: chartData.map((item) => [
            new Date(item.date).getTime(),
            item.unique_installs,
          ]),
        },
      ]
    : []),
    ...(chartOption === "DAUs" 
   ?[
    {
      name: "moreThan5Mint",
      data: chartData.map((item) => [
        new Date(item.date).getTime(),
        item.moreThan5Mint,
      ]),
    },
    {
      name: "moreThan10Mint",
      data: chartData.map((item) => [
        new Date(item.date).getTime(),
        item.moreThan10Mint,
      ]),
    },
    {
      name: "moreThan15Mint",
      data: chartData.map((item) => [
        new Date(item.date).getTime(),
        item.moreThan15Mint,
      ]),
    },
    {
      name: "moreThan30Mint",
      data: chartData.map((item) => [
        new Date(item.date).getTime(),
        item.moreThan30Mint,
      ]),
    },
    {
      name: "moreThan60Mint",
      data: chartData.map((item) => [
        new Date(item.date).getTime(),
        item.moreThan60Mint,
      ]),
    },] : []),
    ...(chartOption === "cumulative Installs" 
    ? [
      {
        name: "Cumulative first time installs",
        data: installsData.reduce((acc, item) => {
          const cumulative = acc.length > 0 ? acc[acc.length - 1][1] + item.unique_installs : item.unique_installs;
          return [...acc, [new Date(item.date).getTime(), cumulative]];
        }, [])
        
      },
    ]
    : []),
    
    
  ];
 

 
  // const handleOptionChange = (option) => {
  //   setChartOption(option);
  //   fetchData(option);
  //   setShowChart(true);
  //   showPdata(false);
  //   setShowCpData(false)
    
    
  // };

  const handleOptionChange = (option) => {
    setChartOption(option);
    
    showPdata(false); // Hide played data
    setShowCpData(false); // Hide CPM data
    
    setShowChart(true); 
    
    
    if (option === "cumulative Installs") {
      fetchData("install");
      
       
    } else {
      fetchData(option); 
    }
  };
  
  const handleTotalPlayedClick = async () => {
    await getPlayedData();
  };
  const handleCPM = async () => {
    // get the price form the subcription send in getCPDATA
    // const price = 
    await getCPMData(200)
  }

  return (
    <div>
      <h5>{game?.game_name}</h5>
      <div style={{ textAlign: "left" }}>
        <button
          onClick={() => handleOptionChange("hours")}
          style={{
            backgroundColor: chartOption === "hours" ? "#2962ff" : "white",
            color: chartOption === "hours" ? "white" : "black",
            border: "none",
          }}
        >
          Hours Played
        </button>
        <button
          onClick={() => handleOptionChange("DAUs")}
          style={{
            backgroundColor: chartOption === "DAUs" ? "#2962ff" : "white",
            color: chartOption === "DAUs" ? "white" : "black",
            border: "none",
          }}
        >
          DAUs
        </button>
        <button
          onClick={() => handleOptionChange("launch")}
          style={{
            backgroundColor: chartOption === "launch" ? "#2962ff" : "white",
            color: chartOption === "launch" ? "white" : "black",
            border: "none",
          }}
        >
          Launches
        </button>
        <button
          onClick={() => handleOptionChange("install")}
          style={{
            backgroundColor: chartOption === "install" ? "#2962ff" : "white",
            color: chartOption === "install" ? "white" : "black",
            border: "none",
          }}
        >
          Installs
        </button>
        <button
          onClick={() => handleOptionChange("cumulative Installs")}
          style={{
            backgroundColor: chartOption === "cumulative Installs" ? "#2962ff" : "white",
            color: chartOption === "cumulative Installs" ? "white" : "black",
            border: "none",
          }}
        >
          Cumulative Installs
        </button>
        <button
          onClick={() => handleOptionChange("click")}
          style={{
            backgroundColor: chartOption === "click" ? "#2962ff" : "white",
            color: chartOption === "click" ? "white" : "black",
            border: "none",
          }}
        >
          Clicks
        </button>
        <button
          onClick={() => handleOptionChange("view")}
          style={{
            backgroundColor: chartOption === "view" ? "#2962ff" : "white",
            color: chartOption === "view" ? "white" : "black",
            border: "none",
          }}
        >
          View
        </button>
        <button
          onClick={handleTotalPlayedClick}
          style={{
            backgroundColor: pdata ? "#2962ff" : "white",
            color: pdata ? "white" : "black",
            border: "none",
          }}
        >
          Total Played
        </button>
        <button
          onClick={handleCPM}
          style={{
            backgroundColor: showCpData ? "#2962ff" : "white",
            color: showCpData ? "white" : "black",
            border: "none",
          }}
        >
          CPM
        </button>
       

       
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : showChart ? (
        <div>
          <div id="chart" className="apexcharts-content">
            <Chart options={options} series={series} type="line" height={400} />
          </div>
        </div>
      ) : pdata ? (
        // 
        <div style={{ textAlign: "left", padding: "10px" }}>
          <h6>Installed Devices: {" "}{`${playedData?.deviceInstallThisGame}`}</h6>
          <h6>
            Total Played Today: {" "}
            {`${playedData?.totalTimePlayToday.hours}h ${playedData?.totalTimePlayToday.minutes}m ${playedData?.totalTimePlayToday.seconds}s`}
          </h6>
          {/* <h6>
            Tokens Earned Today : {" "}
            {`${playedData?.totalTimePlayToday.goldFlakesEarned}`}
          </h6> */}
          <h6>
            Total Played This Month: {" "}
            {`${playedData?.totalTimePlayMonth.hours}h ${playedData?.totalTimePlayMonth.minutes}m ${playedData?.totalTimePlayMonth.seconds}s`}
          </h6>
          {/* <h6>
            Tokens Earned This Month: {" "}
            {`${playedData?.totalTimePlayMonth?.goldFlakesEarned}`}
          </h6> */}
        </div>
      ) : showCpData ? (
        <div style={{ textAlign: "left", padding: "10px" }}>
          <h6>
            CPM : {" "}{`${cpmData?.CPM ? cpmData?.CPM.toFixed(2) : 0}`}
          </h6>
          <h6>
            CPC : {" "}{`${cpmData?.CPC ? cpmData?.CPC.toFixed(2) : 0}`}
          </h6>
          <h6>
            CPI : {" "}{`${cpmData?.CPI ? cpmData?.CPI.toFixed(2) : 0}`}
          </h6>
        </div>
      )  : (
        <></>
      )}

    </div>
  );
};






export default GameAnalytics;

