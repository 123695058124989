import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import the styles
import http from "../utils/Api";
import { FormControl } from "react-bootstrap";

const OverallActivity = ({ activeTab }) => {
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [mobileSearch, setMobileSearch] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const usersPerPage = 10; // Number of users per page
  const pagesVisited = pageNumber * usersPerPage;

  useEffect(() => {
    console.log(activeTab, "THE ACTIVE TAB");
    if (activeTab === "overall") {
      console.log("running", "THE ACTIVE TAB");
      getActivityRange();
    }
  }, [endDate, activeTab]);

  const getActivityRange = async () => {
    setLoading(true);
    const res = await http.get(
      `/api/admin/getPlayersRangeActivity?startDate=${startDate}&endDate=${endDate}&page=${pageNumber + 1
      }`, // Adjusted to include page number
      http.generateConfig()
    );
    if (res.result) {
      setActivityData(res.result);
      setLoading(false);
    }
  };

  const sortUsersByGoldFlakeEarned = (users) => {
    return users.sort((a, b) => b.goldFlake_earned - a.goldFlake_earned);
  };


  const filteredData = sortUsersByGoldFlakeEarned(activityData).filter(
    (user) =>
      (user.email || "").startsWith((mobileSearch)) &&
      (user.mobile || "").startsWith((emailSearch.toLowerCase()))
  );

  const pageCount = Math.ceil(filteredData.length / usersPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <div>
          <div className="pagination-parent">
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={handlePageChange} // Adjusted handler
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              activeLinkClassName={"active"}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: "1"

            }}
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              placeholderText="Start Date"
              className="form-control"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              placeholderText="End Date"
              className="form-control"
              
            />
            <FormControl
              type="text"
              placeholder="Search by Email"
              value={mobileSearch}
              onChange={(e) => setMobileSearch(e.target.value)}
              
            />
            <FormControl
              type="text"
              placeholder="Search by Mobile"
              value={emailSearch}
              onChange={(e) => setEmailSearch(e.target.value)}
              
              
            />
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .slice(pagesVisited, pagesVisited + usersPerPage)
                .map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.mobile || "-"}</td>
                    <td>{user.email || "-"}</td>
                    <td>
                      <h6>
                        {user?.GoldFlakeInCount}, {user?.GoldFlakeOutCount}, ,
                        {user?.boostLogCount}
                        {/* ({user?.GoldFlakeIns.length},{" "}
                        {user?.GoldFlakeOuts.length}, {user?.boostLogs.length}) */}
                      </h6>
                      <Link
                        to={`/activitydetail/${user.id}?activity=${'overall'}&startDate=${startDate}&endDate=${endDate}`}
                      >
                        <Button
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            borderRadius: "5px",
                            border: "1px solid #333",
                            margin: "5px",
                            padding: "5px 10px",
                          }}
                        >
                          View Activity
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )}
    </>
  );
};

export default OverallActivity;
