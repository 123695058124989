import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb, Col,Row,Button,Table,Container, Dropdown,ProgressBar,} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer/Footer";
import http from "../utils/Api";
import * as Icon from "react-feather";
import DashboardStats from "../components/common/dashboardStats";
import { getAllRewardsAc,updateRewardStatus,updateRewardTransacitonId,} from "../service/actions/rewardAction";
import Spinner from "react-bootstrap/Spinner";
import Navigation from "../components/Navigation/Navigation";

const SystemVariables = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [systemData, setSystemData] = useState({

    weeklyLimit: 0,
    weeklyRemainingLimit: 0,
    todayEarnings: 0,
    playerDailyLimit: 0,
    withdrawlLimit: 0,
    tokenLimit: 0,
    nebula: 0,
    eth: 0,
    europa: 0,
    ethFee: 0,
    nebulaFee: 0,
    europaFee: 0,
    polygon: 0,
    polygonFee: 0


  });
  const [editedWeeklyLimit, setEditedWeeklyLimit] = useState(systemData.weeklyLimit)
  const [editedDailyLimit, setEditedDailyLimit] = useState(systemData.playerDailyLimit);
  const [editedWithdrawlLimit, setEditedWithdrawlLimit] = useState(systemData.withdrawlLimit);
  const [editedTokenLimit, setEditedTokenLimit] = useState(systemData.tokenLimit);
  const [editedNebulaLimit, setEditedNebulaLimit] = useState(systemData.nebula);
  const [editedEthLimit, setEditedEthLimit] = useState(systemData.eth);
  const [editedEuropaLimit, setEditedEuropaLimit] = useState(systemData.europa);
  const [editedEuropaFee, setEditedEuropaFee] = useState(systemData.europaFee);
  const [editedEthFee, setEditedEthFee] = useState(systemData.ethFee);
  const [editedNebulaFee, setEditedNebulaFee] = useState(systemData.nebulaFee);
  const [editedpolygonFee, setEditedpolygonFee] = useState(systemData.polygonFee);
  const [editedpolygonLimit, setEditedpolygonLimit] = useState(systemData.polygon);

  // State to track whetther the weekly limit is being edited
  const [isEditingWeeklyLimit, setIsEditingWeeklyLimit] = useState(false);
  const [isEditingDailyLimit, setIsEditingDailyLimit] = useState(false);
  const [isEditingWithdrawlLimit, setIsEditingWithdrawalLimit] = useState(false);
  const [isEditingTokenLimit, setIsEditingTokenLimit] = useState(false);
  const [isEditingNebulaLimit, setIsEditingNebulaLimit] = useState(false);
  const [isEditingEthLimit, setIsEditingEthLimit] = useState(false);
  const [isEditingEuropaLimit, setIsEditingEuropaLimit] = useState(false);
  const [isEditingNebulaFee, setIsEditingNebulaFee] = useState(false);
  const [isEditingEthFee, setIsEditingEthFee] = useState(false);
  const [isEditingEuropaFee, setIsEditingEuropaFee] = useState(false);
  const [isEditingPolygonFee, setIsEditingPolygonFee] = useState(false);
  const [isEditingPolygonLimit, setIsEditingPolygonLimit] = useState(false);
  const [alpha, setAlpha]= useState([])
  const [beta, setbeta]= useState([])
  const [gamma, setGamma]= useState([])
  const [delta, setDelta]= useState([])
 

  const [isEditing, setIsEditing] = useState({});
  const [editedReward, setEditedReward] = useState({});

  // const handleEditClick = (id, currentReward) => {
  //   setIsEditing((prevState) => ({ ...prevState, [id]: true }));
  //   setEditedReward((prevState) => ({ ...prevState, [id]: currentReward }));
  // };

  // const handleRewardChange = (id, value) => {
  //   setEditedReward((prevState) => ({ ...prevState, [id]: value }));
  // };

  // const handleSaveClick = async (id) => {
  //   const newReward = editedReward[id];
  //   const updatedReward = await updateReward(id, newReward);
  //   if (updatedReward) {
  //     // Update the reward in the local state or refetch the data
  //     setIsEditing((prevState) => ({ ...prevState, [id]: false }));
  //   }
  // };

  // const handleEditClick = (id, currentReward) => {
  //   setIsEditing((prevState) => ({ ...prevState, [id]: true }));
  //   setEditedReward((prevState) => ({ ...prevState, [id]: currentReward }));
  // };

  // const handleRewardChange = (id, value) => {
  //   setEditedReward((prevState) => ({ ...prevState, [id]: value }));
  // };

  // const handleSaveClick = async (id) => {
  //   const newReward = editedReward[id];
  //   const updatedReward = await updateReward(id, newReward);
  //   if (updatedReward) {
  //     // Update the reward in the local state
  //     setIsEditing((prevState) => ({ ...prevState, [id]: false }));
  //     // Optionally, update the local state with the new reward data
  //     alpha.forEach(rank => {
  //       if(rank.id === id) {
  //         rank.reward = newReward;
  //       }
  //     });
  //   }
  // };
  const [bottle, setBottle] = useState(false)

  const handleEditClick = (id, currentReward) => {
    setBottle(true)
    setIsEditing((prevState) => ({ ...prevState, [id]: true }));
    setEditedReward((prevState) => ({ ...prevState, [id]: currentReward }));
  };

  const handleRewardChange = (id, value) => {
    setEditedReward((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSaveClick = async (id) => {
    const newReward = editedReward[id];
    try {
      const updatedReward = await updateReward(id, newReward);
      if (updatedReward) {
        console.log(updatedReward,'HTE REWARDSA')
        switch(updatedReward.league){
          case 'Alpha':
            setAlpha((prevState) => prevState.map(rank => (
              rank.id === id ? { ...rank, reward: newReward } : rank
            )));
            setIsEditing((prevState) => ({ ...prevState, [id]: false }));

            case 'Beta':
            setbeta((prevState) => prevState.map(rank => (
              rank.id === id ? { ...rank, reward: newReward } : rank
            )));
            setIsEditing((prevState) => ({ ...prevState, [id]: false }));
            case 'Gamma':
            setGamma((prevState) => prevState.map(rank => (
              rank.id === id ? { ...rank, reward: newReward } : rank
            )));
            setIsEditing((prevState) => ({ ...prevState, [id]: false }));
            case 'Delta':
            setDelta((prevState) => prevState.map(rank => (
              rank.id === id ? { ...rank, reward: newReward } : rank
            )));
            setIsEditing((prevState) => ({ ...prevState, [id]: false }));
          default:
            console.log(`Updated league: ${updatedReward.league}`);
        }
  
        setIsEditing((prevState) => ({ ...prevState, [id]: false }));
      }
    } catch (error) {
      console.error("Error updating reward:", error);
    }
    setBottle(false);
  };
  



  
  useEffect(() => {
    // Fetch system data when the component mounts
    getSystemData();
    getRewardsbyLeague();
  }, []);


  const getSystemData = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        "/api/admin/appStatistics",
        http.generateConfig(token)
      );
      console.log("RESPONSE", res)
      if (res && res.result) {
        setSystemData({ ...res.result });
        setEditedWeeklyLimit(res.result.weeklyLimit); // Initialize the edited value
        setLoading(false); // Mark loading as complete
      }
    } catch (error) {
      console.error("Error fetching system data:", error);
      setLoading(false); // Mark loading as complete even if there's an error
    }
  };

  const getRewardsbyLeague = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const res = await http.get(
        "/api/admin/rewardsbyleague",
        http.generateConfig(token)
      );
      console.log("REWARDS", res)

      if (res) {


        setAlpha(res.Alpha);
        setbeta(res.Beta);
        setGamma(res.Gamma);
        setDelta(res.Delta);
        setLoading(false); 
      }
    } catch (error) {
      console.error("Error fetching league data:", error);
      setLoading(false);
    }
  }
  const updateReward = async (id, newReward) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        `/api/admin/updateRewardsForLeague/${id}`,
        { reward: newReward },
        config
      );
      console.log(response,"THE RESPONSE HER")
      if (!response.status===200) {
        throw new Error('Failed to update reward');
      }
      // const updatedReward = await response.json();
      return response;
    } catch (error) {
      console.error('Error updating reward:', error);
      return null;
    }
  };

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const handleEditWeeklyLimit = () => {
    setIsEditingWeeklyLimit(true);
  };
  const handleEditDailyLimit = () => {
    setIsEditingDailyLimit(true);
  };

  const handleEditWithdrawalLimit = () => {
    setIsEditingWithdrawalLimit(true);
  };
  const handleEditTokenLimit = () => {
    setIsEditingTokenLimit(true);
  };

  const handleEditNebulaLimit = () => {
    setIsEditingNebulaLimit(true);
  };
  const handleEditEthLimit = () => {
    setIsEditingEthLimit(true);
  };
  const handleEditEuropaLimit = () => {
    setIsEditingEuropaLimit(true);
  };
  const handleEditNebulaFee = () => {
    setIsEditingNebulaFee(true);
  };
  const handleEditEthFee = () => {
    setIsEditingEthFee(true);
  };
  const handleEditEuropaFee = () => {
    setIsEditingEuropaFee(true);
  };
  const handleEditPolygonFee = () => {
    setIsEditingPolygonFee(true);
  };
  const handleEditPolygonLimit = () => {
    setIsEditingPolygonLimit(true);
  };






  const updateWeeklyLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateWeeklyLimit",
        { limit },
        config
      );
      if (response.status === 200) {
        console.log("Weekly limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {

      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };

  const updateDailyLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateDailyLimit",
        { limit },
        config
      );
      if (response.status === 200) {
        console.log("Daily limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };

  const updateWithdrawalLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateWithDrawLimit",
        { limit },
        config
      );

      if (response.status === 200) {
        console.log("Withdrawal limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };
  const updateTokenLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateTokenLimit",
        { limit },
        config
      );

      
      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };
  const updateNebulaLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateNebulaLimit",
        { limit },
        config
      );

      
      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };
  const updateEthLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateEThLimit",
        { limit },
        config
      );

      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };
  const updatePolygonLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updatePolygonLimit",
        { limit },
        config
      );

    
      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
    
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };
  const updateEuropaLimit = async (limit) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateEuropaLimit",
        { limit },
        config
      );

      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      console.error("Error updating weekly limit:", err);
      throw err; 
    }
  };
  const updateEuropaFee = async (fee) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateEuropaFee",
        { fee },
        config
      );
      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };
  const updateEthFee = async (fee) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateEthFee",
        { fee },
        config
      );

      // Check the response status or other relevant data if needed
      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
    
      console.error("Error updating weekly limit:", err);
      throw err; 
    }
  };
  const updatePolygonFee = async (fee) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updatePolygonFee",
        { fee },
        config
      );
      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };
  const updateNebulaFee = async (fee) => {
    try {
      const token = localStorage.getItem("accessToken");

      const config = http.generateConfig(token);
      const response = await http.post(
        "/api/admin/updateNebulaFee",
        { fee },
        config
      );

      // Check the response status or other relevant data if needed
      if (response.status === 200) {
        console.log("Token limit updated successfully");
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (err) {
      // Handle errors appropriately (throwing an error or logging)
      console.error("Error updating weekly limit:", err);
      throw err; // Propagate the error for higher-level handling
    }
  };


  const handleSaveWeeklyLimit = () => {
   
    setSystemData({
      ...systemData,
      weeklyLimit: editedWeeklyLimit,
    });

    // Disable editing mode
    setIsEditingWeeklyLimit(false);
    updateWeeklyLimit(editedWeeklyLimit);
  };




  const handleSaveDailyLimit = () => {
    
    setSystemData({
      ...systemData,
      playerDailyLimit: editedDailyLimit,
    });

    // Disable editing mode
    setIsEditingDailyLimit(false);
    updateDailyLimit(editedDailyLimit);
  };

  const handleSaveWithdrawalLimit = () => {
    
    setSystemData({
      ...systemData,
      withdrawlLimit: editedWithdrawlLimit,
    });

    // Disable editing mode
    setIsEditingWithdrawalLimit(false);
    updateWithdrawalLimit(editedWithdrawlLimit);
  };

  const handleSaveNebulaLimit = () => {

    setSystemData({
      ...systemData,
      nebula: editedNebulaLimit,
    });

    // Disable editing mode
    setIsEditingNebulaLimit(false);
    updateNebulaLimit(editedNebulaLimit);
  };
  const handleSaveNebulaFee = () => {
  
    setSystemData({
      ...systemData,
      nebulaFee: editedNebulaFee,
    });

    // Disable editing mode
    setIsEditingNebulaFee(false);
    updateNebulaFee(editedNebulaFee);
  };
  const handleSaveEthLimit = () => {
    
    setSystemData({
      ...systemData,
      eth: editedEthLimit,
    });

    // Disable editing mode
    setIsEditingEthLimit(false);
    updateEthLimit(editedEthLimit);
  };
  const handleSavePolygonLimit = () => {
   
    setSystemData({
      ...systemData,
      polygon: editedpolygonLimit,
    });

    // Disable editing mode
    setIsEditingPolygonLimit(false);
    updatePolygonLimit(editedEthLimit);
  };
  const handleSaveEthFee = () => {
  
    setSystemData({
      ...systemData,
      ethFee: editedEthFee,
    });

    // Disable editing mode
    setIsEditingEthFee(false);
    updateEthFee(editedEthFee);
  };
  const handleSaveEuropaLimit = () => {
  
    setSystemData({
      ...systemData,
      europa: editedEuropaLimit,
    });

    // Disable editing mode
    setIsEditingEuropaLimit(false);
    updateEuropaLimit(editedEuropaLimit);
  };
  const handleSaveEuropaFee = () => {
  
    setSystemData({
      ...systemData,
      europaFee: editedEuropaFee,
    });

    // Disable editing mode
    setIsEditingEuropaFee(false);
    updateEuropaFee(editedEuropaFee);
  };
  const handleSavePolygonFee = () => {
   
    setSystemData({
      ...systemData,
      polygonFee: editedpolygonFee,
    });

    // Disable editing mode
    setIsEditingPolygonFee(false);
    updatePolygonFee(editedpolygonFee);
  };
  const handleSaveTokenLimit = () => {
    
    setSystemData({
      ...systemData,
      tokenLimit: editedTokenLimit,
    });

    // Disable editing mode
    setIsEditingTokenLimit(false);
    updateTokenLimit(editedTokenLimit);
  };

  const handleEditWeeklyLimitChange = (e) => {
    setEditedWeeklyLimit(e.target.value);
  };

  const handleEditDailyLimitChange = (e) => {
    setEditedDailyLimit(e.target.value);
  };

  const handleEditWithdrawalLimitChange = (e) => {
    setEditedWithdrawlLimit(e.target.value);
  };
  const handleEditNebulaLimitChange = (e) => {
    setEditedNebulaLimit(e.target.value);
  };
  const handleEditEthLimitChange = (e) => {
    setEditedEthLimit(e.target.value);
  };
  const handleEditEuropaLimitChange = (e) => {
    setEditedEuropaLimit(e.target.value);
  };

  const handleEditTokenLimitChange = (e) => {
    setEditedTokenLimit(e.target.value);
  };
  const handleEditEthFeeChange = (e) => {
    setEditedEthFee(e.target.value);
  };
  const handleEditEuropaFeeChange = (e) => {
    setEditedEuropaFee(e.target.value);
  };
  const handleEditNebulaFeeChange = (e) => {
    setEditedNebulaFee(e.target.value);
  };
  const handleEditPolygonFeeChange = (e) => {
    setEditedpolygonFee(e.target.value);
  };
  const handleEditPolygonLimitChange = (e) => {

    setEditedpolygonLimit(e.target.value);
  };


  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />

      <div
        className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
          }`}
      >
        <div className="main-content-header">
          <Breadcrumb>
            <h1>System Variables</h1>
          </Breadcrumb>
        </div>
        {loading && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>}

        <div
          className="max-width-container"
          style={{ width: "100%", maxWidth: "100%", margin: "0 auto" }}
        >


          <Row >
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
                Player Daily Limit in %
              </p>
              {isEditingDailyLimit ? (
                <>
                  <input
                    type="number"
                    value={editedDailyLimit}
                    onChange={handleEditDailyLimitChange}
                  />
                  <Icon.Save
                    variant="success"
                    onClick={handleSaveDailyLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: "30px",
                      color: "#04542D",
                      fontWeight: 600,
                      paddingLeft: "5px",
                    }}
                  >
                    {systemData.playerDailyLimit}
                    {"%"}
                  </p>
                  <Icon.Edit
                    variant="primary"
                    onClick={handleEditDailyLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              )}
            </Col>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className=" m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
                Weekly Reward Limit
              </p>
              {isEditingWeeklyLimit ? (
                <>
                  <input
                    type="number"
                    value={editedWeeklyLimit}
                    onChange={handleEditWeeklyLimitChange}
                  />
                  <Icon.Save
                    variant="success"
                    onClick={handleSaveWeeklyLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: "30px",
                      color: "#04542D",
                      fontWeight: 600,
                      paddingLeft: "5px",
                    }}
                  >
                    {systemData.weeklyLimit}
                  </p>
                  <Icon.Edit
                    variant="primary"
                    onClick={handleEditWeeklyLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
                Player Withdrawal Limit
              </p>
              {isEditingWithdrawlLimit ? (

                <>
                  <input
                    type="number"
                    value={editedWithdrawlLimit}
                    onChange={handleEditWithdrawalLimitChange}
                  />
                  <Icon.Save
                    variant="success"
                    onClick={handleSaveWithdrawalLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: "30px",
                      color: "#04542D",
                      fontWeight: 600,
                      paddingLeft: "5px",
                    }}
                  >
                    {systemData.withdrawlLimit}

                  </p>
                  <Icon.Edit
                    variant="primary"
                    onClick={handleEditWithdrawalLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              )}
            </Col>


            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
                Player Token Limit
              </p>
              {isEditingTokenLimit ? (
                <>
                  <input
                    type="number"
                    value={editedTokenLimit}
                    onChange={handleEditTokenLimitChange}
                  />
                  <Icon.Save
                    variant="success"
                    onClick={handleSaveTokenLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize: "30px",
                      color: "#04542D",
                      fontWeight: 600,
                      paddingLeft: "5px",
                    }}
                  >
                    {systemData.tokenLimit}

                  </p>
                  <Icon.Edit
                    variant="primary"
                    onClick={handleEditTokenLimit}
                    style={{
                      cursor: "pointer",
                      transition: "color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "blue")}
                    onMouseLeave={(e) => (e.target.style.color = "initial")}
                  />
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
                Minimun Withdrawal Limit Per Chain
              </p>
              {isEditingNebulaLimit ? (
                <>
                  <input
                    type="number"
                    value={editedNebulaLimit}
                    onChange={handleEditNebulaLimitChange}
                  />
                  <Icon.Save
                    onClick={handleSaveNebulaLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Nebula: {systemData.nebula}{' '}
                  <Icon.Edit
                    onClick={handleEditNebulaLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
              {isEditingEthLimit ? (
                <>
                  <input
                    type="number"
                    value={editedEthLimit}
                    onChange={handleEditEthLimitChange}
                  />
                  <Icon.Save
                    onClick={handleSaveEthLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Ethereum: {systemData.eth}{' '}
                  <Icon.Edit
                    onClick={handleEditEthLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
              {isEditingEuropaLimit ? (
                <>
                  <input
                    type="number"
                    value={editedEuropaLimit}
                    onChange={handleEditEuropaLimitChange}
                  />
                  <Icon.Save
                    onClick={handleSaveEuropaLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Europa: {systemData.europa}{' '}
                  <Icon.Edit
                    onClick={handleEditEuropaLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
              {isEditingPolygonLimit ? (
                <>
                  <input
                    type="number"
                    value={editedpolygonLimit}
                    onChange={handleEditPolygonLimitChange}
                  />
                  <Icon.Save
                    onClick={handleSavePolygonLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Polygon: {systemData.polygon}{' '}
                  <Icon.Edit
                    onClick={handleEditPolygonLimit}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
            </Col>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
                Minimun Withdrawal Fee Per Chain
              </p>
              {isEditingNebulaFee ? (
                <>
                  <input
                    type="number"
                    value={editedNebulaFee}
                    onChange={handleEditNebulaFeeChange}
                  />
                  <Icon.Save
                    onClick={handleSaveNebulaFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Nebula: {systemData.nebulaFee}{' '}
                  <Icon.Edit
                    onClick={handleEditNebulaFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
              {isEditingEthFee ? (
                <>
                  <input
                    type="number"
                    value={editedEthFee}
                    onChange={handleEditEthFeeChange}
                  />
                  <Icon.Save
                    onClick={handleSaveEthFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Ethereum: {systemData.ethFee}{' '}
                  <Icon.Edit
                    onClick={handleEditEthFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
              {isEditingEuropaFee ? (
                <>
                  <input
                    type="number"
                    value={editedEuropaFee}
                    onChange={handleEditEuropaFeeChange}
                  />
                  <Icon.Save
                    onClick={handleSaveEuropaFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Europa: {systemData.europaFee}{' '}
                  <Icon.Edit
                    onClick={handleEditEuropaFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )} {isEditingPolygonFee ? (
                <>
                  <input
                    type="number"
                    value={editedpolygonFee}
                    onChange={handleEditPolygonFeeChange}
                  />
                  <Icon.Save
                    onClick={handleSavePolygonFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                >
                  Polygon: {systemData.polygonFee}{' '}
                  <Icon.Edit
                    onClick={handleEditPolygonFee}
                    style={{
                      cursor: 'pointer',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
            </Col>

          </Row>
        
          
          <Row >
          <Col
        style={{
          borderRadius: '15px',
          backgroundColor: '#fff',
          boxShadow:
            '0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)',
        }}
        className="m-2 mt-0 mb-2"
      >
        <p style={{ fontWeight: '600', fontSize: '15px' }}>League Alpha</p>
        <div>
          {alpha.map((rank) => (
            <div key={rank.id}>
              {isEditing[rank.id] && bottle ? (
                <>
                  <input
                    type="number"
                    value={editedReward[rank.id]}
                    onChange={(e) => handleRewardChange(rank.id, e.target.value)}
                  />
                  <Icon.Save
                    onClick={() => handleSaveClick(rank.id)}
                    style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                style={{
                  fontSize: '15px',
                  color: '#04542D',
                  fontWeight: 600,
                  paddingLeft: '5px',
                }}
                >
                  {rank.league} {rank.rank}: {rank.reward} {rank.rewardType}{' '}
                  <Icon.Edit
                    onClick={() => handleEditClick(rank.id, rank.reward)}
                    style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
            </div>
          ))}
        </div>
      </Col>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className=" m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
              League Beta
              </p>
              <div>
              {
                beta.map((rank) => (
                  <div key={rank.id}>
                  {isEditing[rank.id] && bottle ? (
                    <>
                      <input
                        type="number"
                        value={editedReward[rank.id]}
                        onChange={(e) => handleRewardChange(rank.id, e.target.value)}
                      />
                      <Icon.Save
                        onClick={() => handleSaveClick(rank.id)}
                        style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                        onMouseEnter={(e) => (e.target.style.color = 'blue')}
                        onMouseLeave={(e) => (e.target.style.color = 'initial')}
                      />
                    </>
                  ) : (
                    <p 
                    style={{
                      fontSize: '15px',
                      color: '#04542D',
                      fontWeight: 600,
                      paddingLeft: '5px',
                    }}
                    >
                      {rank.league} {rank.rank}: {rank.reward} {rank.rewardType}{' '}
                      <Icon.Edit
                        onClick={() => handleEditClick(rank.id, rank.reward)}
                        style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                        onMouseEnter={(e) => (e.target.style.color = 'blue')}
                        onMouseLeave={(e) => (e.target.style.color = 'initial')}
                      />
                    </p>
                  )}
                </div>
                ))
              }

              </div>
              
            </Col>
          </Row>
          <Row >
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
                League Gamma
              </p>
              <div>
              {
                gamma.map((rank) => (
                  <div key={rank.id}>
              {isEditing[rank.id] && bottle ? (
                <>
                  <input
                    type="number"
                    value={editedReward[rank.id]}
                    onChange={(e) => handleRewardChange(rank.id, e.target.value)}
                  />
                  <Icon.Save
                    onClick={() => handleSaveClick(rank.id)}
                    style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </>
              ) : (
                <p
                style={{
                  fontSize: '15px',
                  color: '#04542D',
                  fontWeight: 600,
                  paddingLeft: '5px',
                }}
                >
                  {rank.league} {rank.rank}: {rank.reward} {rank.rewardType}{' '}
                  <Icon.Edit
                    onClick={() => handleEditClick(rank.id, rank.reward)}
                    style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                    onMouseEnter={(e) => (e.target.style.color = 'blue')}
                    onMouseLeave={(e) => (e.target.style.color = 'initial')}
                  />
                </p>
              )}
            </div>
                ))
              }

              </div>
              
            </Col>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className=" m-2 mt-0 mb-2"

            >
              <p style={{ fontWeight: "600", fontSize: "15px" }}>
              League Delta
              </p>
              <div>
              {
              delta.map((rank) => (
                <div key={rank.id}>
                {isEditing[rank.id] && bottle ? (
                  <>
                    <input
                      type="number"
                      value={editedReward[rank.id]}
                      onChange={(e) => handleRewardChange(rank.id, e.target.value)}
                    />
                    <Icon.Save
                      onClick={() => handleSaveClick(rank.id)}
                      style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                      onMouseEnter={(e) => (e.target.style.color = 'blue')}
                      onMouseLeave={(e) => (e.target.style.color = 'initial')}
                    />
                  </>
                ) : (
                  <p
                  style={{
                    fontSize: '15px',
                    color: '#04542D',
                    fontWeight: 600,
                    paddingLeft: '5px',
                  }}
                  >
                    {rank.league} {rank.rank}: {rank.reward} {rank.rewardType}{' '}
                    <Icon.Edit
                      onClick={() => handleEditClick(rank.id, rank.reward)}
                      style={{ cursor: 'pointer', transition: 'color 0.3s' }}
                      onMouseEnter={(e) => (e.target.style.color = 'blue')}
                      onMouseLeave={(e) => (e.target.style.color = 'initial')}
                    />
                  </p>
                )}
              </div>
                ))
              }

              </div>
              
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default SystemVariables;