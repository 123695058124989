import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form"; // Import Form component
import ReactPaginate from "react-paginate";


const UserSecurity = ({ users, onToggleState, onEditUser, onDeleteUser }) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items to display per page
  const [idSearchTerm, setIdSearchTerm] = useState("");
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [mobileSearchTerm, setMobileSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all"); // Initialize the status filter
  useEffect(() => {
    if (users.length > 0) {
      setLoading(false);
    }
  }, [users]);

  users.map((items) => {
    console.log(items.mobile);
  });



  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };

  const handleIdSearchChange = (e) => {
    setIdSearchTerm(e.target.value);
    // Reset the current page when searching
  };

  const handleEmailSearchChange = (e) => {
    setEmailSearchTerm(e.target.value);
    // Reset the current page when searching
  };

  const handleMobileSearchChange = (e) => {
    setMobileSearchTerm(e.target.value);
    //setCurrentPage(1); // Reset the current page when searching
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    //setCurrentPage(1); // Reset the current page when changing the status filter
  };

  // Sort users based on goldFlake_earned from higher to lower
  const sortedUsers = users
    .slice()
    .sort((a, b) => b.goldFlake_earned - a.goldFlake_earned);

  const filteredUsers = users.filter((user) => {
    const idString = user.id.toString();
    const emailString = user.email ? user.email.toLowerCase() : "";
    const mobileString = user.mobile ? user.mobile : "";

    // Check if the status filter matches "all" or the user's status
    const statusMatch =
      statusFilter === "all" ||
      (user.isActive && statusFilter === "active") ||
      (!user.isActive && statusFilter === "suspended");

    return (
      idString.startsWith((idSearchTerm)) &&
      emailString.startsWith((emailSearchTerm.toLowerCase())) &&
      mobileString.startsWith((mobileSearchTerm)) &&
      statusMatch
    );
  });

  const offset = currentPage * itemsPerPage;
  // const indexOfLastUser = currentPage * itemsPerPage;
  // const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  //const currentGames = filteredGames.slice(offset, offset + itemsPerPage);


  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    console.log("Selected Page:", selectedPage); // Add this log
    setCurrentPage(selectedPage);
  };
  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);


  const renderedUsers = filteredUsers.slice(offset, offset + itemsPerPage);

  // Calculate the last page
  const lastPage = Math.ceil(filteredUsers.length / itemsPerPage);

  // Calculate the number of page numbers to display
  const displayPageCount = 3; // Change this number as needed

  // Create an array of page numbers based on the current page and display count
  // const pageNumbers = [];
  // const startPage = Math.max(1, currentPage - Math.floor(displayPageCount / 2));
  // const endPage = Math.min(lastPage, startPage + displayPageCount - 1);

  // for (let i = startPage; i <= endPage; i++) {
  //   pageNumbers.push(i);
  // }
  return (
    <div className="">
      <div className="form-content-adduser">
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <div>
            <h3>Security</h3>

            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} // Use the "active" class to highlight the current page
              activeLinkClassName={"active"}
              activeStyle={{ backgroundColor: 'blue', color: 'white' }}
            />

            {/* <div style={{ display: "flex" }}>
              <Button
                variant="secondary"
                onClick={() => paginate(1)}
                disabled={currentPage === 1}
              >
                1
              </Button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {pageNumbers
                  .filter((number) => number !== 1)
                  .slice(0, 3)
                  .map((number) => (
                    <div
                      key={number}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        
                      }}
                    >
                      <Button
                        variant="secondary"
                        onClick={() => paginate(number)}
                        disabled={currentPage === number}
                      >
                        {number}
                      </Button>
                    </div>
                  ))}
              </div>

              <Button
                variant="secondary"
                onClick={() => paginate(lastPage)}
                disabled={currentPage === lastPage}
              >
                {lastPage}
              </Button>
            </div> */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl
                type="text"
                placeholder="Search by ID"
                value={idSearchTerm}
                onChange={handleIdSearchChange}
              />
              <FormControl
                type="text"
                placeholder="Search by Email"
                value={emailSearchTerm}
                onChange={handleEmailSearchChange}
              />
              <FormControl
                type="text"
                placeholder="Search by Mobile"
                value={mobileSearchTerm}
                onChange={handleMobileSearchChange}
              />
              <Form.Control as="select" onChange={handleStatusFilterChange}>
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
              </Form.Control>
            </div>

            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  <th>Player ID</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  {/* <th>GoldFlake Earned</th> */}
                  <th>Monthly Earning Cap</th>
                  {/* <th>Monthly Remaining Cap</th> */}
                  {/* <th>Boosted Monthly Cap</th> */}
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>
                {renderedUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.email || "N/A"}</td>
                    <td>{user.mobile || "N/A"}</td>
                    {/* <td>{user.goldFlake_earned}</td> */}
                    <td>{user.monthly_earning_cap}</td>
                    {/* <td>{user.monthly_remaining_cap}</td> */}
                    {/* <td>{user.boosted_monthly_cap}</td> */}
                    {/* <td>
                      <Button
                        variant={user.isActive ? "danger" : "success"}
                        onClick={() => onToggleState(user)}
                      >
                        {user.isActive ? "Suspend" : "Activate"}
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSecurity;
