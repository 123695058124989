const authReducer = (state = {}, action) => {
  switch (action.type) {
    case "login":
      const accessToken = action.payload.accessToken;
      const refreshToken = action.payload.refreshToken;
      console.log(accessToken,'THE ACCESSS')
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("refreshToken", refreshToken);
      return { ...state, accessToken, refreshToken };
    case "loginWithGoogle":
      const accessToke = action.payload.accessToken;
      localStorage.setItem("accessToken", accessToke);
      return { ...state, accessToken };
    case "logout":
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userRoles");
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("gameStudioId");
      return {};
       
    default:
      return state;
  }
};

export default authReducer;
