import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from ".././components/common/Loader";
import { getStudioGames } from "../service/actions/gameActions";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import UserList from "./UserList";
import { getStudioUsers } from "../service/actions/usersActions";
import { getGameStudio } from "../service/actions/gameStudioActions";
import GameStudioList from "./GameStudioList";


const GameStudio = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const dispatch = useDispatch();
  const studios = useSelector((state) => state.gameStudio);

  useEffect(() => {
    dispatch(getGameStudio());
  }, []);

  // Sort the studios array by a specific property (e.g., studio name)
  const sortedStudios = studios.slice().sort((a, b) => {
    // Replace 'name' with the property you want to sort by
    return a.gs_name.localeCompare(b.gs_name);
  });

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
            }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Game Studio</h1>
            </Breadcrumb>
          </div>
          <div
            className="max-width-container "
            style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}
          >
            <Row>
              <Col >
                <GameStudioList studios={sortedStudios} />
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>


    </>
  );
};

export default GameStudio;
