import React from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import logo from "../assets/img/OneGameLogo.png";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { forgetPasswordApi } from "../service/actions/authActions";
import { useNavigate } from "react-router-dom";

function ForgetPassword() {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState("");
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const onForgetPasswordHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(
        forgetPasswordApi({
          email: formData,
        })
      ).then(() => Navigate("/"));
    }
    setValidated(true);
  };
  return (
    <Container style={{ height: "100vh" }}>
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Col md="6">
          <Image
            src={logo}
            alt="logo"
            className="center"
            width={100}
            style={{
              padding: "1rem",
              borderRadius: "1rem",
              background: "black",
              marginBottom: "1rem",
            }}
          />
          <Form
            style={{
              fontSize: "1rem",
              textAlign: "left",
            }}
            noValidate
            validated={validated}
            onSubmit={onForgetPasswordHandler}
          >
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              {/* <Form.Label column sm="2">
                Email
              </Form.Label> */}
              <Col sm="12">
                <Form.Control
                  placeholder="Enter Your Email"
                  required
                  //   plaintext
                  //    readOnly
                  onChange={(e) => setFormData(e.target.value)}
                  value={formData}
                />
              </Col>
            </Form.Group>
            <Button
              style={{
                width: "100%",
              }}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgetPassword;
