import React from "react";

const TermsAndCondition = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: "100vh",
            textDecoration: 'underline'
        }}>
            <h1>404 NOT FOUND</h1>
        </div>
    );
};

export default TermsAndCondition;
