import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import ReactPaginate from "react-paginate";

const GameStudioList = ({
  studios,
  onToggleState,
  onEditUser,
  onDeleteUser,
}) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // Number of items to display per page

  useEffect(() => {
    if (studios.length > 0) {
      setLoading(false);
    }
  }, [studios]);

  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const pageCount = Math.ceil(studios.length / itemsPerPage);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    console.log("Selected Page:", selectedPage); // Add this log
    setCurrentPage(selectedPage);
  };

  // const paginate = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  // };
  // Calculate the last page
  // const lastPage = Math.ceil(studios.length / itemsPerPage);

  // Calculate the number of page numbers to display
  //const displayPageCount = 3; // Change this number as needed

  //  // Create an array of page numbers based on the current page and display count
  //  const pageNumbers = [];
  //  const startPage = Math.max(1, currentPage - Math.floor(displayPageCount / 2));
  //  const endPage = Math.min(lastPage, startPage + displayPageCount - 1);

  //  for (let i = startPage; i <= endPage; i++) {
  //    pageNumbers.push(i);
  //  }
  return (
    <div className="">
      <div>
        {loading ? (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        ) : (
          <div>
            <h3>Game Studios</h3>
            <div className="pagination-parent">
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"} // Use the "active" class to highlight the current page
                activeLinkClassName={"active"}
              />
            </div>

            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                {studios
                  .slice(indexOfLastUser, indexOfLastUser + itemsPerPage)
                  .map((studio) => (
                    <tr key={studio.id}>
                      {/* <td>{studio.id}</td> */}
                      <td>{studio.gs_name}</td>
                      <td>{studio.contact_email || "N/A"}</td>
                      <td>{studio.contact_phone || "N/A"}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>


          </div>

        )}
      </div>
    </div>
  );
};

export default GameStudioList;
