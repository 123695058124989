import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Tabs, Tab, Table, Breadcrumb, Modal, Form, Button, Alert } from "react-bootstrap";
import Footer from "./Footer/Footer";
import Navigation from "../components/Navigation/Navigation";
import http from "../utils/Api";

const ActivityDetail = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [editedTransactionId, setEditedTransactionId] = useState('');
  const [editIndex, setEditIndex] = useState('');
  const [editingItem, setEditingItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const query = new URLSearchParams(location.search);
  const { id } = useParams()
  console.log(query.get("activity"), '<"THE USER ;sad')
  console.log(query.get("startDate"), '<"THE USER ;sad')
  console.log(query.get("endDate"), '<"THE USER ;sad')


  const [activityData, setActivityData] = useState({
    id: "",
    mobile: "",
    email: "",
    GoldFlakeIns: [],
    boostLogs: [],
    GoldFlakeOuts: []
  })

  useEffect(async () => {
    console.log(query.get('activity'), "THEKJAS");
    if (query.get("activity") === 'overall') {
      console.log(query.get("startDate"), 'THE FIRST IF');
      console.log(query.get("endDate"), 'THE FIRST IF');
      const startDate = query.get('startDate');
      const endDate = query.get('endDate');
      const response = await http.get(`api/admin/getPlayerActivityByPid/${id}?selection=range&startDate=${startDate}&endDate=${endDate}`, http.generateConfig());
      if (response.success) {
        setActivityData(response.result)
      }
    } else {
      console.log('THE FIRST IF 2');
      const response = await http.get(`api/admin/getPlayerActivityByPid/${id}?selection=${query.get("activity")}`, http.generateConfig())
      if (response.success) {
        setActivityData(response.result)
      }
    }
  }, []);

  console.log(activityData, "TEasd")
  const openModal = (item, index) => {
    setEditedTransactionId(item?.transaction_id || '');
    setEditIndex(index)
    setEditingItem(item);
    setShowModal(true);
  };

  const closeModal = () => {
    setEditedTransactionId('');
    setEditingItem(null);
    setShowModal(false);
  };
  const handleSaveTransactionId = async () => {

    if (editedTransactionId.trim() === '') {
      setErrorMessage('Transaction ID cannot be empty');
      return;
    }
    const payload = {
      out_id: editingItem?.id,
      player_id: editingItem?.p_id,
      transaction_id: editedTransactionId
    }
    const token = localStorage.getItem("accessToken");

    const res = await http.post('api/admin/addTransactionId',
      payload,
      http.generateConfig(token)
    )
    if (res) {
      const updatedGoldFlakeOuts = [...activityData.GoldFlakeOuts];
      updatedGoldFlakeOuts[editIndex].transaction_id = editedTransactionId;

      setActivityData(prevData => ({
        ...prevData,
        GoldFlakeOuts: updatedGoldFlakeOuts
      }));


    }

    closeModal();
  };
  const [activeTab, setActiveTab] = useState("goldFlakeIn"); // Default to "GoldFlake In"
  const [sideMenu, setSideMenu] = useState(true);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };
  const removeGoldFlake = async (goldFlake) => {
    const res = await http.put(`/api/admin/updateTokenRecord/${goldFlake?.id}`)
    if (res && res.sucess) {
      const index = activityData.GoldFlakeIns.findIndex(item => item.id === res.result.id);

      if (index !== -1) {
        // Create a copy of the GoldFlakeIns array with the updated record
        const updatedGoldFlakeIns = [...activityData.GoldFlakeIns];
        updatedGoldFlakeIns[index] = res.result;

        // Update the state with the modified GoldFlakeIns array
        setActivityData(prevData => ({
          ...prevData,
          GoldFlakeIns: updatedGoldFlakeIns
        }));
      }
    }
    console.log(res, 'THE RSPONSE')
  }
  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
          }`}
      >
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Activity Details</h1>
          </Breadcrumb>
        </div>
        <div className="max-width-container">
          <div>
            <Tabs
              id="activity-tabs"
              activeKey={activeTab}
              onSelect={(key) => setActiveTab(key)}
            >
              <Tab
                eventKey="goldFlakeIn"
                title={`GoldFlake In (${activityData?.GoldFlakeIns.length})`}
              >
                {activityData?.GoldFlakeIns.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Game</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Duration in sec</th>
                        <th>Reward</th>
                        <th>Gyroscope Count</th>
                        <th>fraud Score</th>
                        <th>EMA</th>
                        <th>Remove</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityData.GoldFlakeIns.sort((a, b) =>
                        a.start_time.localeCompare(b.start_time)
                      ) // Sort by start_time
                        .map((item) => (
                          <tr key={item.id}>
                            <td>{item.Game?.name}</td>
                            <td>{formatDate(item.start_time)}</td>
                            <td>{formatDate(item.end_time)}</td>
                            <td>{item.duration}s</td>
                            <td>{item.rewarded}</td>
                            <td>{item.gyroscopeCount}</td>
                            <td>{item.fraudScore}</td>
                            <td>{item.emaFraudScore}</td>
                            <td><Button variant="danger" onClick={() => removeGoldFlake(item)}>Remove</Button></td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No data available</p>
                )}
              </Tab>

              <Tab
                eventKey="goldFlakeOut"
                title={`GoldFlake Out (${activityData.GoldFlakeOuts.length})`}
              >
                {activityData.GoldFlakeOuts.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Reason</th>
                        <th>GiftCard</th>
                        <th>Flakes</th>
                        <th>Transaction Id</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityData.GoldFlakeOuts.map((item, index) => (
                        <tr key={item.id}>
                          <td>{item.reason}</td>
                          <td>{item?.playerGiftCard?.giftCardTitle || "-"}</td>
                          <td>{item?.total}</td>
                          <td>
                            {item?.transaction_id || '-'}
                          </td>
                          <td>
                            <Button variant="primary" onClick={() => openModal(item, index)}>
                              Edit Transaction Id
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No data available</p>
                )}
              </Tab>
              <Tab
                eventKey="boostLogs"
                title={`BoostLogs In (${activityData.boostLogs.length})`}
              >
                <p>For invite reward will only show when boost state is used</p>
                {activityData.boostLogs.length > 0 ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Boost</th>
                        <th>Type</th>
                        <th>Description</th>
                        <th>Referral</th>
                        <th>Reward</th>
                        <th>state</th>
                        <th>Expiry</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activityData.boostLogs.map((item) => (
                        <tr key={item.id}>
                          <td>{item?.boost}</td>
                          <td>{item?.Action?.rewardBooster?.rewardType}</td>
                          <td>{item?.Action?.description || '-'}</td>
                          <td>{item?.referral_code}</td>
                          <td>{item?.Action?.rewardBooster?.rewardAmount}</td>
                          <td>{item?.state}</td>
                          <td>{item?.expiryDate ? formatDate(item?.expiryDate) : 'no expiry'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <p>No data available</p>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Transaction ID</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            value={editedTransactionId}
            onChange={(e) => setEditedTransactionId(e.target.value) + setErrorMessage('')}

          />
          {/* {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveTransactionId}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ActivityDetail;
