import http from "../../utils/Api";
import { getAllRewards, getReward, updateReward } from "../actionTypes/types";




const getAllRewardsAc = (data , tab) => async (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  try {
    let res
    if(tab === "web2"){
        res = await http.get(`/api/admin/getTokenOut?page=${data}&limit=100`, http.generateConfig(accessToken));
    }else if(tab === "web3"){
        res = await http.get(`/api/admin/getAllPointRedemptions?page=${data}&limit=100`, http.generateConfig(accessToken));
    }

    console.log(res,"HTE RES HERE")
    if(res && res.result){

        dispatch({
            type:getAllRewards,
            payload:res.result
        })
    }
    if( res && res.totalPages){
        return res.totalPages
    }


  } catch (err) {
    console.log(err);
  }
};



const updateRewardStatus = (data)=> async(dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");

        const { id, status ,statusReason , tab} = data;
        let dat
        if(tab === "web2"){
            dat = await http.post(
                `/api/admin/changeTokenStatus`,
                {
                    id,
                    status,
                    statusReason
                },
                http.generateConfig()
            )
    
        }else{
            dat = await http.post(
                `/api/admin/changeWeb3RecordStatus`,
                {
                    id,
                    status,
                    statusReason
                },
                http.generateConfig()
            )
            console.log("data-->" , dat)
        }
        if(dat.data){
            console.log(dat,'JANSDKLJNASKJAS')

            dispatch({
                type:updateReward,
                payload:dat.data
            })
        }

    }
    catch(err){
        console.log(err)
    }
}



const updateRewardTransacitonId = (data)=> async(dispatch)=>{
    try{
        const token = localStorage.getItem("accessToken");

        const dat = await http.post(
            `/api/admin/addTransactionId`,
            data,
            http.generateConfig()
        )
        if(dat.result){
            dispatch({
                type:updateReward,
                payload:dat.result
            })
        }

    }
    catch(err){
        console.log(err)
    }
}


export {
    getAllRewardsAc,
    updateRewardStatus,
    updateRewardTransacitonId
}