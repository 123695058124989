import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Image, InputGroup } from "react-bootstrap";
import * as Icon from "react-feather";
import { signup, signinGSWithGoogle } from "../service/actions/authActions";
// Logo image file path
import Logo from "../assets/img/OneGameLogo.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { signInWithGooglePopup } from "../services/firebase";
import http from "../utils/Api";
import googleIcon from "../assets/img/googleIcon.png";
import { AiFillEye } from "react-icons/ai";
const Signup = (props) => {
  const [validated, setValidated] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    website: "",
  });

  // const googleProvider = new GoogleAuthProvider();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  useEffect(() => {
    const storedToken = localStorage.getItem("accessToken");
    if (storedToken) {
      navigate("/home");
    }
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const formRef = useRef(null); // Ref for the form element

  const onSignupHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      dispatch(signup(formData)).then(() => {
        const storedToken = localStorage.getItem("accessToken");
        if (storedToken) {
          navigate("/home");
        } else {
          navigate("/");
        }
      });
    }
    setValidated(true);
  };
  const handleEmailSignupClick = () => {
    // Scroll to the form when "Sign Up with Email" is clicked
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const signInWithGoogle = async () => {
    try {
      const response = await signInWithGooglePopup();
      const data = {
        gs_name: response?.user?.providerData[0]?.displayName,
        contact_email: response?.user?.providerData[0].email,
        googleId: response?.user?.providerData[0].uid,
        profile_picture: response?.user?.providerData[0]?.photoURL,
      };
      dispatch(signinGSWithGoogle(data)).then(() => {
        const storedToken = localStorage.getItem("accessToken");
        if (storedToken) {
          navigate("/home");
        }
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  return (
    <div className="auth-main-content">
      <div className="d-table">
        <div className="d-tablecell">
          <div className="auth-box">
            <Row className="align-items-center">
              <Col md={6}>
                <div className="form-left-content">
                  <div className="auth-logo">
                    <Image src={Logo} alt="Logo" />
                  </div>

                  <div className="login-links">
                    <Link to="#" className="fb">
                      <Icon.Facebook className="icon" />
                      Sign Up with Facebook
                    </Link>
                    <Link to="#" className="google" onClick={signInWithGoogle}>
                      {/* <Icon.Twitter className="icon" /> */}
                      <img
                        src={googleIcon} // Use your Google icon path here
                        alt="Google"
                        style={{ width: "18px", marginRight: "8px" }}
                      />
                      Sign Up with Google
                    </Link>
                    <Link
                      to="#"
                      className="ema"
                      onClick={handleEmailSignupClick}
                    >
                      <Icon.Mail className="icon" />
                      Sign Up with Email
                    </Link>
                    <Link to="#" className="linkd">
                      <Icon.Linkedin className="icon" />
                      Sign Up with Linkedin
                    </Link>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="form-content" ref={formRef}>
                  <h1 className="heading">Sign Up</h1>
                  <Form
                    style={{ textAlign: "left" }}
                    noValidate
                    validated={validated}
                    onSubmit={onSignupHandler}
                  >
                    <Form.Group className="mb-3">
                      <Form.Label>Your name</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          required
                          type="password"
                          name="password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <InputGroup.Text
                          style={{
                            marginLeft: "-35px",
                            zIndex: 1,
                            fontSize: "0.9rem",
                            background: "none",
                            color: "black",
                            border: "none",
                          }}
                        >
                          @
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </Form.Group> */}
                    <Form.Group className="mb-3">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        required
                        type="number"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Website URL</Form.Label>
                      <Form.Control
                        required
                        type="text"
                        name="website"
                        value={formData.website}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <div className="text-center">
                      <Button variant="primary" type="submit">
                        Sign Up
                      </Button>

                      <div className="signup-text">
                        Already have an account?{" "}
                        <Link to="/" className="fp-link2">
                          Log in
                        </Link>
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
