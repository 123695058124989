import React, { useState } from "react";
import Navigation from "./../components/Navigation/Navigation";
import { Row, Breadcrumb, Col, Badge, Form, Button, Table, FormControl } from "react-bootstrap";
import http from "../utils/Api";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const Record = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [walletValue, setWalletValue] = useState("")
  const [playerRecord, setPlayerRecord] = useState(null);
  const [walletData, SetWalletData] = useState([]);
  const [playersData, setPlayersData] = useState([]);
  const [walletClicked, setWalletClicked] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [walletSearchTerm, setWalletSearchTerm] = useState("");
  const [reasonSearchTerm, setReasonSearchTerm] = useState("");






  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim() === "") return; 
    const res = await http.post(`/api/admin/getPlayerEarningDetail`, { emailOrMobile: inputValue }, http.generateConfig());
    if (res.success) {
      setPlayersData([]);
      setWalletClicked(true)

      setPlayerRecord(res.result);
      SetWalletData(res.result.playerWallets)
      setInputValue("")

    }
  };

  const handleWalletSubmit = async (e) => {
    e.preventDefault();
    if (walletValue.trim() === "") return; // Check if wallet value is not empty

    getSameWalletPlayers(walletValue);
    setWalletClicked(false);
  };
  const itemsPerPage = 10;
  const itemsPerPage2 = 10;
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfLastUser2 = currentPage2 * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const pageCount = Math.ceil(walletData.length / itemsPerPage);
  const pageCount2 = Math.ceil(playersData.length / itemsPerPage)

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };


  const handlePageClick2 = (data) => {
    const selectedPage = data.selected;
    setCurrentPage2(selectedPage);
  };



  const getSameWalletPlayers = async (wallet, p_id) => {
    const token = localStorage.getItem("accessToken");
    const res = await http.post("api/admin/checkWalletUsedByDiffPlayers",
      { wallet, p_id },
      http.generateConfig(token)
    );

    setPlayersData([]);

    setPlayersData(res.result);
    setWalletValue("")
    // console.log(playersData)

  };

  const handleWalletSearchChange = (e) => {
    setWalletSearchTerm(e.target.value);

  };

  const handleReasonSearchChange = (e) => {
    setReasonSearchTerm(e.target.value);

  };


  const filteredUsers = walletData.filter((user) => {
    const walletString = user.wallet ? user.wallet.toLocaleLowerCase() : "";
    const reasonString = user.reason ? user.reason.toLocaleLowerCase() : "";



    return (
      walletString.startsWith((walletSearchTerm.toLocaleLowerCase())) &&
      reasonString.startsWith((reasonSearchTerm.toLocaleLowerCase()))
    );
  });

  const currentUsers = filteredUsers.slice(indexOfLastUser, indexOfLastUser + itemsPerPage);
  const currentUsers2 = playersData.slice(indexOfLastUser2, indexOfLastUser2 + itemsPerPage2)



  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
            }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Records</h1>
            </Breadcrumb>
            <Row>
              <Col sm={6}>
                <Form onSubmit={handleSubmit}>
                  <div className="row" style={{ marginBottom: "32px" }}>
                    <div className="col-md-8 d-flex">
                      <Form.Group controlId="inputValue" className="flex-grow-1 mr-2">
                        {/* <Form.Label>Player Detail</Form.Label> */}
                        <Form.Control
                          type="text"
                          placeholder="Enter player email or phone number"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </Form.Group>

                      <Button variant="primary" onClick={handleSubmit} style={{marginLeft: "10px"}}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
                </Col>
                <Col sm={6}>
                <Form onSubmit={handleWalletSubmit}>
                  <div className="row" style={{ marginBottom: "32px" }}>
                    <div className="col-md-8 d-flex">
                      <Form.Group controlId="walletValue" className="flex-grow-1 mr-2">
                        {/* <Form.Label>Player Detail</Form.Label> */}
                        <Form.Control
                          type="text"
                          placeholder="Enter player wallet"
                          value={walletValue}
                          onChange={(e) => setWalletValue(e.target.value)}
                        />
                      </Form.Group>

                      <Button variant="primary" onClick={handleWalletSubmit}>
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
            { walletClicked && playerRecord && (
              <>
                <Row>
                  <Col sm={12}>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Current Month Earned</th>
                          <th>Expected Earning</th>
                          <th>Player Current Token</th>
                          <th>Player ID</th>
                          <th>Player Token Difference</th>
                          <th>Total Token Earned</th>
                          <th>Total Token Out</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{playerRecord.currentMonthEarned}</td>
                          <td>{playerRecord.expectedEarning}</td>
                          <td>{playerRecord.playerCurrentToken}</td>
                          <td>{playerRecord.playerId}</td>
                          <td>{playerRecord.playerTokenDifference}</td>
                          <td>{playerRecord.totalTokenEarned}</td>
                          <td>{playerRecord.totalTokenOut}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="pagination-parent">
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} // Use the "active" class to highlight the current page
                        activeLinkClassName={"active"}
                      />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <FormControl
                        type="text"
                        placeholder="Search by Wallet"
                        value={walletSearchTerm}
                        onChange={handleWalletSearchChange}
                      />
                      <FormControl
                        type="text"
                        placeholder="Search by Reason"
                        value={reasonSearchTerm}
                        onChange={handleReasonSearchChange}
                      /></div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Player ID</th>
                          <th>Wallet</th>
                          <th>Status</th>
                          <th>Reason</th>
                          <th>Created At</th>

                        </tr>
                      </thead>
                      <tbody>
                        {currentUsers.map((user) => (
                          <tr key={user.id || "N/A"}>
                            <td>{user.id || "N/A"}</td>
                            <td>{user.p_id || "N/A"}</td>
                            <td onClick={() => {
                              getSameWalletPlayers(user.wallet, user.p_id)
                              
                            }}>
                              {user.wallet || "N/A"}
                            </td>
                            <td>{user.status || "N/A"}</td>
                            <td>{user.reason || "N/A"}</td>
                            <td>{user.createdAt || "N/A"}</td>

                          </tr>))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                
              </>
            )}
            { playersData.length > 0 && (
                  <Row>
                    <Col sm={12}>
                      <div className="pagination-parent">
                        <ReactPaginate
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={pageCount2}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={handlePageClick2}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"} // Use the "active" class to highlight the current page
                          activeLinkClassName={"active"}
                        />
                      </div>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Player ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Mobile</th>
                            <th>Status</th>
                            <th>Wallet</th>
                            <th>Created At</th>
                            <th>Reason</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentUsers2.map((user) => (
                            <tr key={user.id}>
                              <td>{user.id}</td>
                              <td>{user.p_id || "N/A"}</td>
                              <td>{user.Player.name || "N/A"}</td>
                              <td>{user.Player.email || "N/A"}</td>
                              <td>{user.Player.mobile || "N/A"}</td>
                              <td>{user.status || "N/A"}</td>
                              <td>{user.wallet || "N/A"}</td>
                              <td>{user.createdAt || "N/A"}</td>
                              <td>{user.reason || "N/A"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                )}


          </div>
        </div>
      </div>
    </>
  );
};

export default Record;
