import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import FormControl from "react-bootstrap/FormControl";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";

const MonthlyActivity = ({ monthlyActiviteUsers }) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0); // Page numbers start from 0
  const itemsPerPage = 10;
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [mobileSearchTerm, setMobileSearchTerm] = useState("");

  useEffect(() => {
    if (monthlyActiviteUsers.length > 0) {
      setLoading(false);
    }
  }, [monthlyActiviteUsers]);

  const sortUsersByGoldFlakeEarned = (users) => {
    return users.sort((a, b) => b.goldFlake_earned - a.goldFlake_earned);
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const indexOfLastUser = (currentPage + 1) * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;

  const filteredUsers = sortUsersByGoldFlakeEarned(monthlyActiviteUsers).filter(
    (user) =>
      (user.email || "").startsWith((emailSearchTerm.toLowerCase())) &&
      (user.mobile || "").startsWith((mobileSearchTerm))
  );

  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);

  const offset = currentPage * itemsPerPage;
  const paginatedUsers = filteredUsers.slice(offset, offset + itemsPerPage);

  return (
    <div>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <div>
          <div
                      className="pagination-parent"

          >
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              activeLinkClassName={"active"}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FormControl
              type="text"
              placeholder="Search by Email"
              value={emailSearchTerm}
              onChange={(e) => setEmailSearchTerm(e.target.value)}
            />
            <FormControl
              type="text"
              placeholder="Search by Mobile"
              value={mobileSearchTerm}
              onChange={(e) => setMobileSearchTerm(e.target.value)}
            />
          </div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Mobile</th>
                <th>Email</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.mobile || "-"}</td>
                  <td>{user.email || "-"}</td>
                  <td>
                    <h6>
                    {user?.GoldFlakeInCount},{" "}{user?.GoldFlakeOutCount},{" "},{user?.boostLogCount}

                      {/* ({user?.GoldFlakeIns.length},{" "}
                      {user?.GoldFlakeOuts.length}, {user?.boostLogs.length}) */}
                    </h6>
                    <Link
                      to={`/activitydetail/${user.id}?activity=${'monthly'}`}
                    >
                      <Button
                        style={{
                          backgroundColor: "blue",
                          color: "white",
                          borderRadius: "5px",
                          border: "1px solid #333",
                          margin: "5px",
                          padding: "5px 10px",
                        }}
                      >
                        View Activity
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default MonthlyActivity;
