import React from "react";

const Footer =()=>{
    return (
        <footer className="footer mt-2">
          <p>
            Copyright © Earnscape. All Rights Reserved{" "}
            {/* <a
              href="https://envytheme.com/"
              target="_blank"
              rel="noopener noreferrer"
            > */}
              Earnscape
            {/* </a> */}
          </p>
        </footer>
      );
}

export default Footer;
