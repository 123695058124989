import http from "../../utils/Api";
import { add, get, getAll, update, remove, mark, unmark } from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import axios from "axios";

const getStudioGames = (data) => async (dispatch) => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  try {
   const res = await  http.get('/api/admin/getAllGames',http.generateConfig(accessToken))
    if (res) {
      dispatch({
        type: getAll,
        payload: res.result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const getSingleGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data; //game ID
    const res = await http.get(
      `/api/gameProfile/${id}`,
      data,
      http.generateConfig(token)
    );
    console.log(res.data, "THE ASD");
    dispatch({
      type: get,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
const addGames = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const dat = await http.post(
      `/api/game/addGame/${id}`,
      data,
      http.generateConfig(token)
    );
    if (dat.result) {
      dispatch({
        type: add,
        payload: dat.result,
      });
    }
    //  link the game form the mobile bakcend
  } catch (err) {
    console.log(err);
  }
};

const updateGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data;
    const dat = await http.post(
      `/api/admin/blockGameById`,
      {g_id: id},
      http.generateConfig(token)
    );
    dispatch({
      type: update,
      payload: dat.result,
    });
  } catch (err) {
    console.log(err);
  }
};


export const markGameFeatured = (id, featureType) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await http.post(
      '/api/admin/markGameFeatures',
      { id, type : featureType },
      http.generateConfig(token)
    );

    console.log(" response.data.id", response.result.id);
// success if 
    dispatch({
      type: mark, // update
      payload: response.result.id,
    });
  } catch (err) {
    console.error(err);
  }
};

export const unmarkGameFeatured = (id) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    console.log("sdadaddddddd");
    const response = await http.post(
      '/api/admin/unmarkGameFeatures',
      { id },
      http.generateConfig(token)
    );
    dispatch({
      type: unmark,
      payload: response.result.id,
    });
  } catch (err) {
    console.error(err);
  }
};



const deleteGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id, gs_id } = data; //game ID
    const dat = await http.post(
      `/api/game/unlinkStudioGame/`,
      { gameId: id, studioId: gs_id },
      http.generateConfig(token)
    );
    dispatch({
      type: remove,
      payload: dat.result,
    });
    // change the tier of the game if its elevated
  } catch (err) {
    console.log(err);
  }
};

export { getStudioGames, getSingleGame, addGames, updateGame, deleteGame };
