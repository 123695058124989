import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';
import http from '../../utils/Api';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import Spinner from "react-bootstrap/Spinner";
import { Calendar } from 'react-feather';






const DAUChart = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const [chartData, setChartData] = useState({
    categories: Array.from({ length: 24 }, (_, hour) => `Hour ${hour}`),
    userCounts: [],
    tokenSeries: [],
  });

  useEffect(() => {
    const fetchChartData = async (date) => {
      try {
        setLoading(true)
        const token = localStorage.getItem("accessToken");
        const formattedDate = format(date, 'yyyy-MM-dd'); // Format date as YYYY-MM-DD
        const response = await http.get(`api/admin/getDAUsChart?date=${formattedDate}`, http.generateConfig(token));
        console.log(response, "THE DATA FROM CHART");
        const data = response.data[0]; // Adjust to access the correct data structure
        const categories = Array.from({ length: 24 }, (_, hour) => `Hour ${hour}`);
        const userCounts = data.userCounts;
        const tokenSeries = Array.from({ length: 24 }, (_, hour) => ({
          name: `Hour ${hour}`,
          data: [data.tokensPerHour[hour] || 0]
        }));

        setChartData({
          categories,
          userCounts,
          tokenSeries,
        });
        setLoading(false);
      } catch (error) {
        console.error('Error fetching chart data:', error);
      }
    };

    fetchChartData(selectedDate);
  }, [selectedDate]);

  const options = {
    chart: {
      height: 350,
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
      },
    },
    colors: [
      'rgb(4, 84, 45)'
    ],
    series: [
      {
        name: 'Tokens Earned',
        type: 'bar',
        data: chartData.tokenSeries.map(series => series.data[0]),
      },
      {
        name: 'Active Users',
        type: 'line',
        data: chartData.userCounts,
        color: '#FFD700', // Gold color for the line chart
      },
    ],
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: [
      {
        title: {
          text: 'Tokens Earned',
        },
      },
      {
        opposite: true,
        title: {
          text: 'Daily Active Users',
        },
      },
    ],
    tooltip: {
      shared: true,
      intersect: false,
    },
    legend: {
      position: 'bottom',
    },
    stroke: {
      curve: 'smooth',
      width: [0, 3], // Set the stroke width for the bar and line chart
    },
    markers: {
      size: [0, 5], // Set the marker size for the bar and line chart
    },
    grid: {
      padding: {
        bottom: 40,
      },
    },
  };

  return (
    <div>
      {loading ? (
        // <Spinner animation="border" role="status">
        //   <span className="sr-only">Loading...</span>
        // </Spinner>
        <p>Loading...</p>
      ) : (
        <div id="chart">
          
            
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              className="custom-date-picker"
              
            />

          <ApexCharts options={options} series={options.series} height={350} />
        </div>)}
    </div>
  );
};

export default DAUChart;
