import { add, get, getAll, update, remove, mark, unmark } from "../actionTypes/types";

const gameReducer = (state = [], action) => {
  console.log("action.type", action);
  switch (action.type) {
    case add:
      // Assuming action.payload contains a single game object
      return [...state, action.payload];
    case get:
      // Find and return the game with the given ID
      return state.find((game) => game.id === action.payload.id);
    case getAll:
      // Assuming action.payload contains an array of game objects
      return action.payload;
      case update:
        return state.map((game) =>
          game.id === action.payload.id ? action.payload : game
        );
        case mark:
      // Assuming action.payload contains the ID of the game to be marked as featured
      return state.map((game) =>
      game.id === action.payload ? { ...game, featured: true } : game
      );
    case unmark:
      // Assuming action.payload contains the ID of the game to be unmarked as featured
      return state.map((game) =>
      game.id === action.payload ? { ...game, featured: false } : game
      );
       
    case remove:
      // Remove the game with the given ID from the state
      return state.filter((game) => game.id !== action.payload.id);
    default:
      return state;
  }
};

export default gameReducer;
