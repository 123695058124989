import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import Footer from "./Footer/Footer";
import Navigation from "../components/Navigation/Navigation";
import http from "../utils/Api";
import { Breadcrumb, Col, Row, Table, FormControl } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

const WeeklyTopPlayers = () => {


    // const today = new Date();
    // const startOfPreviousWeek = new Date(today);
    // startOfPreviousWeek.setDate(today.getDate() - today.getDay() - 6); 
    

    // let startofLastWeek = startOfPreviousWeek.toISOString().split('T')[0];
   

    const [loading, setLoading] = useState(true);
    const [players, setPlayers] = useState([]);

    const [sideMenu, setSideMenu] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    


    const onSideMenu = (active) => {
        setSideMenu(active);
    };

    useEffect(() => {
       
        
        getTopPlayers(startDate);
    }, [startDate]);

 

    const getTopPlayers = async (startDate) => {
        const token = localStorage.getItem("accessToken");
        try {
            setLoading(true);
            const formattedSDate = format(startDate, 'yyyy-MM-dd');
            
            const res = await http.get(
                `/api/admin/top-players?startDate=${formattedSDate}`,
                http.generateConfig(token)
            );
            console.log("RESPONSE", res)
            if (res !== null && res.result !== null) {
                setPlayers(res);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching system data:", error);
            setLoading(false); // Mark loading as complete even if there's an error
        }
    };

    



    return (
        <>
            <div className="page-wrapper">
                <Navigation onClick={onSideMenu} />
                <div
                    className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
                        }`}
                >
                    <div className="main-content-header">
                        <Breadcrumb>
                            <h1>Weekly Top Players</h1>
                        </Breadcrumb>
                    </div>
                    {loading ? (<p>Loading...</p>) : (

                    <div style={{ maxHeight: '400px', overflowY: "auto" }}>
                        <div>

                            

                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                dateFormat="yyyy-MM-dd"
                                className="custom-date-picker"

                            />

                            


                        </div>


                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    {/* <th>ID</th> */}
                                    <th>Player ID</th>
                                    <th>Rank</th>
                                    <th>Score</th>
                                    <th>Reward</th>
                                    <th>Boost</th>
                                    <th>League</th>
                                    <th>Week Number</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>

                                </tr>
                            </thead>
                            <tbody>

                                {players.length > 0 ? (
                                    <>
                                        {players.map((user, index) => (
                                            <tr key={index}>
                                                <td>{user?.p_id || "-"}</td>
                                                <td>{user.rank || "-"}</td>

                                                <td>{user?.score || "-"}</td>
                                                <td>{user?.reward || 0}</td>
                                                <td>{user?.boost}</td>
                                                <td>{user?.league || "-"}</td>
                                                <td>{user.weekNumber || "-"}</td>

                                                <td>{user?.startDate || "-"}</td>
                                                <td>{user?.endDate || "-"}</td>
                                            </tr>
                                        ))}
                                    </>
                                ) : (
                                    <span>Users not found</span>
                                )}
                            </tbody>
                        </Table>

                    </div>)}
                </div>
            </div>

            <div className="flex-grow-1">
                <Footer />
            </div >

        </>
    );



}

export default WeeklyTopPlayers;