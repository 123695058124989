import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import logo from "../assets/img/OneGameLogo.png";
import { confirmedPassword } from "../service/actions/authActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function ForgetPasswordAuth() {
  const { paramsToken } = useParams();
  const [formData, setFormData] = useState({
    password: "",
    confirmedPassword: "",
  });
  const [validated, setValidated] = useState(false);
  const { id, role } = jwt_decode(paramsToken);
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const onConfirmedPasswordHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else if (formData.password === formData.confirmedPassword) {
      dispatch(
        confirmedPassword(
          {
            password: formData,
            id,
            role,
          },
          paramsToken
        )
      ).then(() => Navigate("/"));
    } else {
      toast.error("Password Not matches");
    }
    setValidated(true);
  };
  return (
    <Container style={{ height: "100vh" }}>
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Col md="6">
          <Image
            src={logo}
            alt="logo"
            className="center"
            width={100}
            style={{
              padding: "1rem",
              borderRadius: "1rem",
              background: "black",
              marginBottom: "1rem",
            }}
          />
          <Form
            style={{
              fontSize: "1rem",
              textAlign: "left",
            }}
            noValidate
            validated={validated}
            onSubmit={onConfirmedPasswordHandler}
          >
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextEmail"
            >
              <Form.Label column sm="2">
                Password
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  required
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  value={formData.password}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextPassword"
            >
              <Form.Label column sm="2">
                Confirmed Password
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  type="password"
                  placeholder="Confirmed Password"
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      confirmedPassword: e.target.value,
                    })
                  }
                  value={formData.confirmedPassword}
                />
              </Col>
            </Form.Group>
            <Button
              style={{
                width: "100%",
              }}
              variant="primary"
              type="submit"
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgetPasswordAuth;
