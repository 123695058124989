import http from "../../utils/Api";
import { getAllKYC } from "../actionTypes/types";








const getAllPlayersKyc =(data)=> async(dispatch)=>{
    const accessToken = localStorage.getItem("accessToken");
    const refreshToken = localStorage.getItem("refreshToken");
    try {
     const res = await  http.get('/api/admin/getAllPlayersKyc',http.generateConfig(accessToken))
      if (res) {
        dispatch({
          type: getAllKYC,
          payload: res.result,
        });
      }
    } catch (error) {
      console.log(error);
    }
}

export { getAllPlayersKyc }