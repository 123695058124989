import { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Navigation from "../components/Navigation/Navigation";
import luckyPatcher from "../assets/img/luckyPatcher.png";
import creeHck from "../assets/img/creehack.jpg";
import appSara from "../assets/img/appsara.jpg";
import leoPlayCard from "../assets/img/Leoplay Card.jpg";
import sbGamesHack from "../assets/img/SB Game Hacker.webp";
import freedom from "../assets/img/Freedom.jpg";
import gameKiller from "../assets/img/GameKiller.webp";
import cheatEngine from "../assets/img/Cheat Engine.jpg";
import gameGuardian from "../assets/img/Game Guardian.webp";
import xmods from "../assets/img/Xmod Games.png";
import BeeNetwork from "../assets/img/Bee Network.png"
import "../App.css";
import http from "../utils/Api";

const Fraud = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const dispatch = useDispatch();

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const [warnedUsers, setWarnedUsers] = useState([]);
  const [bannedUsers, setBannedUsers] = useState([]);
  const bannedApps = [
    { title: "Lucky Patcher", image: luckyPatcher },
    { title: "AppSara", image: appSara },
    { title: "Cree Hack", image: creeHck },
    { title: "Leo Play Card", image: leoPlayCard },
    { title: "SB Game Hack", image: sbGamesHack },
    { title: "Freedom", image: freedom },
    { title: "Game Killer", image: gameKiller },
    { title: "Cheat Engine", image: cheatEngine },
    { title: "Game Guardian", image: gameGuardian },
    { title: "X Mods", image: xmods },
    {
      title: "Bee Network", image:BeeNetwork
    }
  ];

  const getBlackList = () => {
    try {
      const token = localStorage.getItem("accessToken");
      http
        .get("/api/admin/getAllBlacklistPlayers", http.generateConfig(token))
        .then((data) => {
          console.log(data, "TEH WAAER Block");
          if ( data !== null && data.result !== null) {
            setBannedUsers(data.result);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getWarnedList = () => {
    try {
      const token = localStorage.getItem("accessToken");
      http
        .get("/api/admin/getWarningPlayerList", http.generateConfig(token))
        .then((data) => {
          if (data !== null && data.result !== null) {
            setWarnedUsers(data.result);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWarnedList();
    getBlackList();
  }, []);
  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Fraud</h1>
            </Breadcrumb>
          </div>
          <h3>Banned Apps</h3>
          <div className="game-list-container">
            <div className="game-list">
              {bannedApps.map((app, index) => (
                <div key={index} className="game-item">
                  <img src={app.image} alt={app.title} width={50} height={50} />
                  <p>{app.title}</p>
                </div>
              ))}
            </div>
          </div>

          <Row>
            <Col md={6}>
              <h6 style={{ position: 'sticky', top: 0,  zIndex: 1 }}>Warned Users</h6>
              <div style={{ maxHeight: '400px', overflowY: "auto" }}>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>player</th>
                  </tr>
                </thead>
                <tbody>
                {
                  warnedUsers.length >0 ? (
                    <>
                      {warnedUsers.map((user, index) => (
                    <tr key={index}>
                      <td>{user?.id || "-"}</td>
                      <td>{user?.mobile || user.email || "-"}</td>
                    </tr>
                  ))}
                    </>
                  ) :
                  (<span  >
                  no user Found
                  </span>)
                }
                </tbody>
              </Table>
              </div>
            </Col>
            <Col md={6} >
              <h6 style={{ position: 'sticky', top: 0,  zIndex: 1 }}>Black listed Users</h6>
              <div style={{ maxHeight: '400px', overflowY: "auto" }}>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>player</th>
                  </tr>
                </thead>
                <tbody>
                  {bannedUsers.length > 0 ? (
                    <>
                      {bannedUsers.map((user, index) => (
                        <tr key={index}>
                          <td>{user?.id || "-"}</td>
                          <td>{user?.mobile || user.email || "-"}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <span>Users not found</span>
                  )}
                </tbody>
              </Table>
              </div>
              
            </Col>
          </Row>
        </div>
      </div>
      
    </>
  );
};

export default Fraud;
