import { getAllUsersPermission } from "../actionTypes/types";

const usersPermissionReducer = (state = [], action) => {
  switch (action.type) {
    case getAllUsersPermission:
      // Assuming action.payload contains a single user object
      return action.payload;
    default:
      return state;
  }
};

export default usersPermissionReducer;
