import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

const ButtonLoader = () => {
  return (
    <div 
    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Spinner animation="border" role="status" variant='light' size='sm'>
      <span className="visually-hidden"></span>
    </Spinner>
    </div>
  )
}

export default ButtonLoader