import { useEffect, useState } from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import Navigation from "../components/Navigation/Navigation";
import { useDispatch, useSelector } from "react-redux";
import { getAllPlayersKyc } from "../service/actions/KycAction";
import KYCList from "./KycList";
import Footer from "./Footer/Footer";



const KYC = () => {
    const [sideMenu, setSideMenu] = useState(true);
    const dispatch = useDispatch();

    const userKYC = useSelector((state) => state.KYC)

    const onSideMenu = (active) => {
        setSideMenu(active);
    };


    useEffect(() => {
        dispatch(getAllPlayersKyc())
    }, [])
    return (
        <>
        <div className="page-wrapper">
          <Navigation onClick={onSideMenu} />
          <div
            className={`main-content d-flex flex-column ${
              sideMenu ? "hide-sidemenu" : ""
            }`}
          >
            <div className="main-content-header">
              <Breadcrumb>
                <h1>User KYC</h1>
              </Breadcrumb>
            </div>
            <div>
            
  
            </div>
            <div
              className="max-width-container"
              style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}
            >
              <Row>
                <Col md={12} >
                <KYCList userKYC={userKYC} />
                </Col>
              </Row>
            </div>
            <div className="flex-grow-1"></div>
            <Footer />
          </div>
        </div>
      </>
    );
}

export default KYC;