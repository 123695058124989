import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const SuspiciousConfirmationModal = ({ user, onMark, onUnmark, markedUsers, setMarkedUsers }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    if (user.isSuspicious === true) {
      onUnmark(user.id);
      setMarkedUsers(markedUsers.filter(id => id !== user.id));
    } else {
      onMark(user.id);
      setMarkedUsers([...markedUsers, user.id]);
    }
    handleCloseModal();
  };

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant={user.isSuspicious ? "danger" : "success"}
      >
        {user.isSuspicious ? "Not Suspicious" : "Suspicious"}
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} backdrop="static" style={{marginTop:"100px"}}>
        <Modal.Header closeButton>
          <Modal.Title>{user.isSuspicious ? "Mark as Not Suspicious" : "Mark as Suspicious"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>Are you sure you want to {user.isSuspicious ? <>mark <b>{user.email ? user.email : user.mobile ? user.mobile : "NA"}</b> as not suspicious?</> : <>mark <b>{user.email ? user.email : user.mobile ? user.mobile : "NA"}</b> as suspicious?</>}</>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {user.isSuspicious ? "Mark as Not Suspicious" : "Mark as Suspicious"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SuspiciousConfirmationModal;
