import { getGameStudios, updateGameStudios } from "../actionTypes/types";

const initialState = null; // Initialize the state with null for a single object

const gameStudioReducer = (state = [], action) => {
  switch (action.type) {
    case getGameStudios:
      return action.payload;
    case updateGameStudios:
        return state.map((gameStudio) =>
        gameStudio.id === action.payload.id ? action.payload : gameStudio
        );
    default:
      return state;
  }
};

export default gameStudioReducer;
