import React from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Suspended() {
  const Navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRoles");
    Navigate("/");
    toast("logout successfully", "success");
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Your Account Has Been Suspended By Game Studio</h1>
      <Button size="lg" variant="danger" onClick={handleLogout}>
        LogOut
      </Button>
    </div>
  );
}

export default Suspended;
