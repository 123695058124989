import React, { useState, useEffect } from "react";
import { Row, Breadcrumb, Tabs, Tab } from "react-bootstrap";
import Navigation from "./../components/Navigation/Navigation";
import DailyActivity from "./DailyActivity";
import MonthlyActivity from "./MonthlyActivity";
import OverallActivity from "./OverallActivity";
import Footer from "./Footer/Footer";
import http from "../utils/Api";

const Activity = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [activeTab, setActiveTab] = useState("daily");
  const [dailyActiviteUsers, setDailyActiviteUsers] = useState([]);
  const [monthlyActiviteUsers, setMonthlyActiviteUsers] = useState([]);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const fetchData = async (tab) => {
    try {
      let res;
      if (tab === "daily") {
        res = await http.get("/api/admin/getPlayersDailyActivity", http.generateConfig());
        setDailyActiviteUsers(res.result || []);
      } else if (tab === "monthly") {
        res = await http.get("/api/admin/getPlayersMonthlyActivity", http.generateConfig());
        setMonthlyActiviteUsers(res.result || []);
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab]);

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}>
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Activity</h1>
          </Breadcrumb>
        </div>
        <div className="max-width-container">
          <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
            <Tab eventKey="daily" title="Daily Activity">
              <DailyActivity dailyActiviteUsers={dailyActiviteUsers} />
            </Tab>
            <Tab eventKey="monthly" title="Monthly Activity">
              <MonthlyActivity monthlyActiviteUsers={monthlyActiviteUsers} />
            </Tab>
            <Tab eventKey="overall" title="OverAll Activity">
              <OverallActivity activeTab={activeTab} />
            </Tab>
          </Tabs>
        </div>
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
    </div>
  );
};

export default Activity;
