import { getSingleUserById} from "../actionTypes/types";

const initialState = null; // Initialize the state with null for a single object

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case getSingleUserById:
      // Return the fetched game studio object
      return action.payload.user;
          default:
      return state;
  }
};

export default userProfileReducer;
