import React, { useEffect, useState } from "react";

import {
  Breadcrumb,
  Col,
  Row,
  Spinner,
  Table,
  Form,
  Modal,
  Button,
} from "react-bootstrap";

import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import http from "../utils/Api";
import { toast } from "react-toastify";

const columns = [
  { name: "ID", minWidth: "60px", key: "id" },
  { name: "Name", minWidth: "100px", key: "name" },
  { name: "Impact Value", minWidth: "120px", key: "impactValue" },
  { name: "Status", minWidth: "120px", key: "isActive" },
];

const FraudRating = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [pendingRowId, setPendingRowId] = useState(null);
  const [newStatus, setNewStatus] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };
  const accessToken = localStorage.getItem("accessToken");

  const fetchFraduRating = async () => {
    try {
      const res = await http.get(
        "/api/admin/getFraudRiskFactors",
        http.generateConfig(accessToken) // Ensure accessToken is available in your context
      );
      if (res) {
        setData(res.data.data);
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFraduRating();
  }, []);

  const handleStatusToggle = (rowId, row, newStatus) => {
    setPendingRowId(row);
    setNewStatus(newStatus);
    setShowModal(true); // Show confirmation modal
  };

  const handleConfirm = async () => {
    setConfirmLoading(true); // Start loading spinner
    try {
      const response = await http.put(`/api/admin/updateFraudRiskStatus/${pendingRowId?.id}`, {
        isActive: newStatus, // Send the new status
      },
      http.generateConfig(accessToken) // Ensure accessToken is available in your context
    );
    if(response){
      setData((prevData) =>
        prevData?.map((item) =>
          item.id === pendingRowId.id ? { ...item, isActive: response.data.isActive } : item
        )
      );
      toast.success("Status updated successfully")
    }
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setConfirmLoading(false); // Stop loading spinner
      setShowModal(false); // Close the modal
      setPendingRowId(null); // Reset pending row id
    }
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Fraud Rating</h1>
          </Breadcrumb>
        </div>

        <div
          className="max-width-container"
          style={{ width: "100%", maxWidth: "99%", margin: "0 auto" }}
        >
          <Row>
            <Col md={12}>
              <div className="game-list">
                <div className="form-content-adduser container-fluid">
                  {loading ? (
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    <div>
                      <Table
                        className="align-middle"
                        bordered
                        hover
                        responsive
                        style={{
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                          maxWidth: "100%",
                        }}
                      >
                        <thead>
                          <tr>
                            {columns?.map((column, index) => (
                              <th
                                key={index}
                                style={{
                                  minWidth: `${column.minWidth}`,
                                  maxWidth: "350px",
                                }}
                              >
                                {column.name}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody>
                          {data?.map((row, rowIndex) => {
                            return (
                              <tr key={rowIndex}>
                                {columns?.map((column, colIndex) => (
                                  <td
                                    key={colIndex}
                                    className="text-capitalize"
                                  >
                                    {column.key === "isActive" ? (
                                      <Form.Check
                                        type="switch"
                                        id={`status-switch-${row.id}`}
                                        checked={row.isActive}
                                        onChange={(e) => {
                                          handleStatusToggle(
                                            row.id,
                                            row,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    ) : (
                                      row?.[column.key]
                                    )}
                                  </td>
                                ))}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Status Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to change the status of{" "}
          <strong>{pendingRowId?.name}</strong> risk factor to{" "}
          <strong>{newStatus ? "True" : "False"}</strong> ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={confirmLoading} // Disable button while loading
          >
            {confirmLoading ? (
              <Spinner animation="border" className="custom-spinner" />
            ) : (
              "Confirm"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FraudRating;
