import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from ".././components/common/Loader";
import { getStudioGames } from "../service/actions/gameActions";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import UserList from "./UserList";
import { clearUserDeviceA, getStudioUsers, updateUser } from "../service/actions/usersActions";
import http from "../utils/Api";
import { markAsSus, unMarkAsSus} from "../service/actions/usersActions";
import { updateUsers } from "../service/actionTypes/types";


const User = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [MAU, setMAU] = useState([])
  const [DAU, setDAU] = useState([])

  useEffect(() => {
    dispatch(getStudioUsers());
    getUniqueMAUs()
    getUniqueDAUs()
  }, []);


  
  

  // Sort the users array by a specific property (e.g., id)
  const sortedUsers = users.slice().sort((a, b) => {
    // Replace 'id' with the property you want to sort by
    return a.id - b.id;
  });

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const onToggleState = (user) => {
    dispatch(updateUser(user));
  };
  const handleClearDevice = (user) => {
    dispatch(clearUserDeviceA(user));
  }

  const getUniqueMAUs = async () => {
    try {
       const token = localStorage.getItem("accessToken");

      const MAUs = await http.get('/api/admin/getMAUs', http.generateConfig(token));
      console.log(MAUs, 'THE MONTHLY ACTIVE USERS')
      if (!!MAUs) {
        setMAU(MAUs);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
   
  }

  const getUniqueDAUs = async () => {
    try {
      const token = localStorage.getItem("accessToken");
    const DAUs = await http.get('/api/admin/getDAUs', http.generateConfig(token));
    if (!!DAUs) {
      setDAU(DAUs)
    }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    
  }

  // const handleDeleteUser = async(user)=>{
  //   try{

      


  //     const token = localStorage.getItem("accessToken");
  //     if (!token) {
  //       throw new Error("No access token found");
  //     }
  //     const config = http.generateConfig(token);
  //   if (!config) {
  //     throw new Error("Failed to generate config");
  //   }

  //     const deletedUser = await http.post('/api/admin/deleteUser', user,config);
  //     if(!!deletedUser && deletedUser.result){
  //       console.log("User deleted successfully:", deletedUser.result);
  //       dispatch(updateUsers(deletedUser.result))
  //     }
  //   }
  //   catch(err){
  //     console.log(err)
  //   }
  // }

  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
            }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Users</h1>
            </Breadcrumb>
          </div>
          <div
            className="max-width-container"
            style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}
          >

            <Row>
              <Col md={6}   >
                <h6 style={{ position: 'sticky', top: 0,  zIndex: 1 }}>Unique Monthly Active Users</h6>
                <div style={{ maxHeight: '400px', overflowY: "auto" }}>
                <Table hover responsive >
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>player</th>
                    </tr>
                  </thead>
                  <tbody>
                    {MAU.map((user, index) => (
                      <tr key={index}>
                        <td>{user.playerData.id}</td>
                        <td>{user.playerData.mobile || user.playerData.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </div>

              </Col>
              <Col md={6}  >
                <h6 style={{ position: 'sticky', top: 0,  zIndex: 1 }}>Unique Daily Active Users</h6>
                <div style={{ maxHeight: '400px', overflowY: "auto" }}>
                <Table hover responsive >
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>player</th>
                    </tr>
                  </thead>
                  <tbody>
                    {DAU.map((user, index) => (
                      <tr key={index}>
                        <td>{user.playerData.id}</td>
                        <td>{user.playerData.mobile || user.playerData.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                </div>

              </Col>
            </Row>
            <div >
              <Row> 
                <Col md={12} >
                  <UserList  onToggleState={onToggleState}
                   handleClearDevice={handleClearDevice}
                  //  onDeleteUser={handleDeleteUser}
                   />
                   
                </Col>
              </Row>
            </div>
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default User;
