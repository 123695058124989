import React, { useEffect, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Loader from "../components/common/Loader";
import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import DashboardStats from "../components/common/dashboardStats";
import http from "../utils/Api";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from "react-bootstrap/Spinner";



const Impression = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const [activeRange, setActiveRange] = useState("Today");
  const [userImpressions, setUserImpressions] = useState({
    totalImpressions: {},
    averageImpressionsPerPlayer: {},
    impressionsPerUser: [],
  });
  const [totalImpression, setTotalImpression] = useState({
    allGames: {
      installs: { total: 0, average: 0 },
      launches: { total: 0, average: 0 },
    },
    featuredGames: {
      installs: { total: 0, average: 0 },
      launches: { total: 0, average: 0 },
    },
  });
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dispatch = useDispatch();

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  useEffect(() => {
    getImpressionStats("day");
    
  }, []);

  const getImpressionStats = async (range) => {
    const token = localStorage.getItem("accessToken");
    try {
      setLoading(true);
      const response = await http.get(
        `api/admin/totalImpression?timeRange=${range}`,
        http.generateConfig(token)
      );
      if (response && response.success) {
        setLoading(false);
        setTotalImpression({ ...response.data });
        setUserImpressions({ ...response.data });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching impression stats:", error);
    }
  };

  const handleRangeSelection = (range) => {
    setActiveRange(range);
    switch (range) {
      case "Today":
        getImpressionStats("day");
        break;
      case "WTD":
        getImpressionStats("week");
        break;
      case "MTD":
        getImpressionStats("month");
        break;
      case "YTD":
        getImpressionStats("year");
        break;
      case "ALLTIME":
        getImpressionStats("alltime");
        break;
      case "CUSTOM":
        // Handle CUSTOM range
        break;
      default:
        break;
    }
  };

  const handleCustomRange = async () => {
    const startDateStr = startDate.toISOString().split("T")[0];
    const endDateStr = endDate.toISOString().split("T")[0];
    getCustomImpressionStats(startDateStr, endDateStr);
  };

  const getCustomImpressionStats = async (startDate, endDate) => {
    const token = localStorage.getItem("accessToken");
    try {
      setLoading(true);


      const response = await http.get(
        `api/admin/totalImpression?timeRange=${'custom'}&customStartDate=${startDate}&customEndDate=${endDate}`,
        http.generateConfig(token)
      );
      if (response && response.success) {
        setLoading(false);
        setTotalImpression({ ...response.data });
        setUserImpressions({ ...response.data });
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching custom impression stats:", error);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          {loading &&  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spinner >
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>}

          <div className="main-content-header">
            <Breadcrumb>
              <h1>Impressions</h1>
            </Breadcrumb>
          </div>

          <div className="max-width-container">
            <div className="range-boxes">
              <div
                className={`range-box  ${
                  activeRange === "Today" ? "active" : ""
                }` }
                
                onClick={() => handleRangeSelection("Today")}
              >
                Today
              </div>
              <div
                className={`range-box ${activeRange === "WTD" ? "active" : ""}`}
                onClick={() => handleRangeSelection("WTD")}
              >
                WTD
              </div>
              <div
                className={`range-box ${activeRange === "MTD" ? "active" : ""}`}
                onClick={() => handleRangeSelection("MTD")}
              >
                MTD
              </div>
              <div
                className={`range-box ${activeRange === "YTD" ? "active" : ""}`}
                onClick={() => handleRangeSelection("YTD")}
              >
                YTD
              </div>
              <div
                className={`range-box ${
                  activeRange === "ALLTIME" ? "active" : ""
                }`}
                onClick={() => handleRangeSelection("ALLTIME")}
              >
                ALLTIME
              </div>
              <div
                className={`range-box ${
                  activeRange === "CUSTOM" ? "active" : ""
                }`}
                onClick={() => handleRangeSelection("CUSTOM")}
              >
                CUSTOM
              </div>
            </div>

            {/* Date pickers for custom range */}
            {activeRange === "CUSTOM" && (
              <div>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                />
                <button onClick={handleCustomRange}>Apply</button>
              </div>
            )}

            {/* Display dashboard stats */}
            <DashboardStats
              statistics={[
                {
                  title: "Total Installs",
                  count: totalImpression.allGames.installs.total,
                  tooltip: true,
                  tooltipText: "Total Installs of Games",
                },
                {
                  title: "Avg Installs",
                  count: totalImpression.allGames.installs.average.toFixed(2),
                  tooltip: true,
                  tooltipText: "Average Installs of Games",
                },
                {
                  title: "Total Launches",
                  count: totalImpression.allGames.launches.total,
                  tooltip: true,
                  tooltipText: "Total Launch of Games",
                },
                {
                  title: "Avg Launches",
                  count: totalImpression.allGames.launches.average.toFixed(2),
                  tooltip: true,
                  tooltipText: "Average Launch of Games",
                },
              ]}
            />
            <DashboardStats
              statistics={[
                {
                  title: "Featured Games Installs",
                  count: totalImpression.featuredGames.installs.total,
                  tooltip: true,
                  tooltipText: "Total Installs of Featured Games",
                },
                {
                  title: " Featured Games Avg Installs",
                  count:
                    totalImpression.featuredGames.installs.average.toFixed(2),
                  tooltip: true,
                  tooltipText: "Average Installs of Featured Games",
                },
                {
                  title: " Featured Games Launches",
                  count: totalImpression.featuredGames.launches.total,
                  tooltip: true,
                  tooltipText: "Total Launch of Featured Games",
                },
                {
                  title: " Featured Games Avg Launches",
                  count:
                    totalImpression.featuredGames.launches.average.toFixed(2),
                  tooltip: true,
                  tooltipText: "Average Launch of Featured Games",
                },
              ]}
            />
            <DashboardStats
              statistics={[
                {
                  title: "Installs From App",
                  count: userImpressions.totalImpressions.installs,
                  tooltip: true,
                  tooltipText: "Total Install from App",
                },
                {
                  title: "Views",
                  count: userImpressions.totalImpressions.shows,
                  tooltip: true,
                  tooltipText: "Total Views from App",
                },
                {
                  title: "Clicks",
                  count: userImpressions.totalImpressions.clicks,
                  tooltip: true,
                  tooltipText: "Total Clicks on gameDetails page",
                },
              ]}
            />
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Impression;
