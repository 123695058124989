import { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, Table, FormControl } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Navigation from "../components/Navigation/Navigation";
import ReactPaginate from "react-paginate";
import Footer from "./Footer/Footer";
import SystemVariables from "./systemVariables";

import "../App.css";
import http from "../utils/Api";

const FailedAttempts = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const dispatch = useDispatch();

  const onSideMenu = (active) => {
    setSideMenu(active);
  };
  const [failedUsers, setFailedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [idSearchTerm, setIdSearchTerm] = useState("");
  const [emailSearchTerm, setEmailSearchTerm] = useState("");
  const [mobileSearchTerm, setMobileSearchTerm] = useState("");
  const [deviceIdSearchTerm, setDeviceIdSearchTerm] = useState("");
  const itemsPerPage = 10;
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const pageCount = Math.ceil(failedUsers.length / itemsPerPage);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };



  const getfailedUsers = () => {
    try {
      const token = localStorage.getItem("accessToken");
      console.log("http", http)
      http
        .get("/api/admin/failedlogin", http.generateConfig(token))
        .then((data) => {
          console.log(data, "failed Users");
          if (data !== null && data.result !== null) {
            setFailedUsers(data.result);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  const handleIdSearchChange = (e) => {
    setIdSearchTerm(e.target.value);

  };

  const handleEmailSearchChange = (e) => {
    setEmailSearchTerm(e.target.value);

  };

  const handleMobileSearchChange = (e) => {
    setMobileSearchTerm(e.target.value);

  };
  const handleDeviceIdSearchChange = (e) => {
    setDeviceIdSearchTerm(e.target.value);
  };


  const filteredUsers = failedUsers.filter((user) => {
    const idString = user.id.toString();
    const emailString = user.email ? user.email.toLocaleLowerCase() : "";
    const mobileString = user.mobile ? user.mobile.toLocaleLowerCase() : "";
    const deviceIdString = user.deviceId ? user.deviceId.toLocaleLowerCase() : "";



    return (
      idString.startsWith((idSearchTerm.toLocaleLowerCase())) &&
      emailString.startsWith((emailSearchTerm.toLocaleLowerCase())) &&
      mobileString.startsWith((mobileSearchTerm.toLocaleLowerCase())) &&
      deviceIdString.startsWith(deviceIdSearchTerm.toLowerCase())

    );
  });



  useEffect(() => {
    getfailedUsers()
  }, []);
  const currentUsers = filteredUsers.slice(indexOfLastUser, indexOfLastUser + itemsPerPage);
  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
            }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Failed Log In Attempts</h1>
            </Breadcrumb>
          </div>
          <div className="pagination-parent">
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} // Use the "active" class to highlight the current page
              activeLinkClassName={"active"}
            />
          </div>
          <div style={{ maxHeight: '400px', overflowY: "auto" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <FormControl
                type="text"
                placeholder="Search by ID"
                value={idSearchTerm}
                onChange={handleIdSearchChange}
              />
              <FormControl
                type="text"
                placeholder="Search by Email"
                value={emailSearchTerm}
                onChange={handleEmailSearchChange}
              />
              <FormControl
                type="text"
                placeholder="Search by Mobile"
                value={mobileSearchTerm}
                onChange={handleMobileSearchChange}
              />
              <FormControl
                type="text"
                placeholder="Search by Device ID"
                value={deviceIdSearchTerm}
                onChange={handleDeviceIdSearchChange}
              />
            </div>

            <Table responsive striped bordered hover>
              <thead>
                <tr>
                  {/* <th>ID</th> */}
                  <th>ID</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Device ID</th>
                </tr>
              </thead>
              <tbody>

                {currentUsers.length > 0 ? (
                  <>
                    {currentUsers.map((user, index) => (
                      <tr key={index}>
                        <td>{user?.id || "-"}</td>
                        <td>{user.email || "-"}</td>

                        <td>{user?.mobile || "-"}</td>
                        <td>{user?.deviceId || "-"}</td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <span>Users not found</span>
                )}
              </tbody>
            </Table>

          </div>
        </div>
      </div>
      
      <div className="flex-grow-1">
        <Footer />
      </div >

    </>
  );
};

export default FailedAttempts;
