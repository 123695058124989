import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Row, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from ".././components/common/Loader";
import { getStudioGames } from "../service/actions/gameActions";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import UserList from "./UserList";
import { getStudioUsers, updateUser } from "../service/actions/usersActions";
import UserSecurity from "./UserSecurity";

const Security = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  

  useEffect(() => {
    dispatch(getStudioUsers());
  }, []);

  // Sort the users array by a specific property (e.g., id)
  const sortedUsers = users.slice().sort((a, b) => {
    // Replace 'id' with the property you want to sort by
    return a.id - b.id;
  });

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const onToggleState = (user) => {
    dispatch(updateUser(user));
  };
 
  
  return (
    <>
      <div className="page-wrapper">
        <Navigation onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "hide-sidemenu" : ""
          }`}
        >
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Security</h1>
            </Breadcrumb>
          </div>
          
          <div
            className="max-width-container"
            style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}
          >
            
            <Row>
              <Col md={12}>
                
                <UserSecurity
                  users={sortedUsers}
                  onToggleState={onToggleState}
                />
                
              </Col>
            </Row>
          </div>
          <div className="flex-grow-1"></div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Security;
