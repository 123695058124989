import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import { BsInfoCircle } from "react-icons/bs"; // Import the information icon
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import { startOfMonth, endOfMonth, format } from "date-fns";
import enUS from 'date-fns/locale/en-US';

const DashboardStats = ({ statistics, }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [target, setTarget] = useState(null);
  const [tooltipText, setTooltipText] = useState("");
  const tooltipRef = useRef(null);

  const handleClick = (event, text) => {
    setTooltipText(text);
    setShowTooltip(true);
    setTarget(event.target);
  };

  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Row className="mt-3">
        {statistics.map((stats, index) => (
          <React.Fragment key={index}>
            <Col
              style={{
                borderRadius: "15px",
                backgroundColor: "#fff",
                boxShadow:
                  "0px 0px 0px 0px rgba(0, 17, 83, 0.05), 7.989px 0px 18.64px 0px rgba(0, 17, 83, 0.05), 209.034px 0px 58.583px 0px rgba(0, 17, 83, 0.00)",
              }}
              className="m-2 mt-0 mb-2"
            >
              <div ref={tooltipRef} style={{ textAlign: "right" }} >
                {stats.tooltip && (
                  <BsInfoCircle
                    onClick={(event) => handleClick(event, stats.tooltipText)}
                    style={{ cursor: "pointer", marginLeft: "5px" }}
                  />
                )}
              </div>
              <Row className="align-items-center p-2 pb-0">
                <p style={{ fontWeight: "600", fontSize: "15px" }}>
                  {stats.title}
                </p>
                <p
                  style={{
                    fontSize: "30px",
                    color: "#04542D",
                    fontWeight: 600,
                    paddingLeft: "5px",
                  }}
                >
                  {stats.count}
                </p>
                {/* Add the information icon */}
              </Row>
            </Col>
          </React.Fragment>
        ))}
      </Row>
      {/* Render tooltip */}
      <Overlay
        show={showTooltip}
        target={target}
        placement="top"
        transition={false}
      >
        {(props) => (
          <Tooltip id="tooltip" {...props}>
            {tooltipText}
          </Tooltip>
        )}
      </Overlay>
    </div>
  );
};

export default DashboardStats;
