import React, { useState, useEffect } from "react";
import { Form, Spinner, Button, FormControl, Breadcrumb } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import * as Icon from "react-feather";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation";
import http from "../utils/Api";
import { Badge } from "react-bootstrap";
import { FaSyncAlt } from 'react-icons/fa';
// import http from "../utils/Api";


const ActiveUsers = () => {
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sideMenu, setSideMenu] = useState(true);
  const [currentActive, setCurrentActive]= useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [endSessionVisible, setEndSessionVisible] = useState(false);

  useEffect(()=>{
    getActiveUsers()
    

  },[]);

  useEffect(() => {
    
    console.log("Selected rows:", selectedRows);
    if (selectedRows.length === 0) {
      setEndSessionVisible(false);

    }else{
    setEndSessionVisible(selectedRows.length > 0);
    }
  }, [selectedRows]);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  const getActiveUsers = async () => {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    console.log(config);
    const response = await http.get(`/api/admin/getActivePlayers`,
    http.generateConfig(token));
    if (response && response.sucess) {
      setCurrentActive(response.result);
      console.log(currentActive, "skjsjs");
    }
  };

  const EndSession = async (user) => {
   try {
    const token = localStorage.getItem("accessToken");
    const config = http.generateConfig(token);
    console.log(config);
    const response = await http.post(`/api/admin/endPlayerSession/${user?.id}`, {}, config );
   
    if (response && response.sucess) {
      // Remove the ended user from the currentActive array
      setCurrentActive(currentActive.filter((u) => u.id !== user.id));
      // Show a popup indicating that the player's session has ended
      alert("Player session ended successfully.");
    } else {
      // Show a popup indicating failure to end the session
      alert("Failed to end player session.");
    }
   } catch (error) {
    console.log(error);
   }
  };

  // for (const userId of selectedRows) {
    //   const user = currentActive.find(user => user.id === userId);
    //   if (user) {
    //     console.log("Ending session for user:", user.id);
    //     await EndSession(user);
    //   }
    // } 
    // console.log("");

  const EndSelectedSessions = async () => {
    console.log("ending sessions for selected rows", selectedRows);
    const token = localStorage.getItem("accessToken");
    const response = await http.post("/api/admin/bulkActivePlayerLogout", {
      pids: selectedRows

    },
    http.generateConfig(token)
  
  )
    if (response && response.sucess) {
      // Remove the ended user from the currentActive array
      const updatedActive = currentActive.filter((user) => !selectedRows.includes(user.id));
      setCurrentActive(updatedActive);

      

      
      // Show a popup indicating that the player's session has ended
      alert("Sessions ended successfully.");
    } else {
      // Show a popup indicating failure to end the session
      alert("Failed to end player session.");
    }
    
    setSelectedRows([]);
  };


  // const toggleRowSelection = (id) => {
  //   if (selectedRows.includes(id)) {
  //     setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
  //   } else {
  //     setSelectedRows([...selectedRows, id]);
  //   }
  // };
  const handleRowClick = (id) => {
    // Check if the clicked row is already selected
    const isSelected = selectedRows.includes(id);
    
    // Toggle row selection based on current selection status
    if (isSelected) {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  return (
    <div className="page-wrapper">
      <Navigation onClick={onSideMenu} />
      <div className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}>
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Active Users</h1>
          </Breadcrumb>
        </div>
        <div>

        <div style={{ display:"flex", justifyContent:"space-between", marginBottom: "5px" }}>
            <h5>Count:{`${currentActive.length}`}</h5>
            <div>
            {endSessionVisible && (
  <Button variant={"secondary"} style={{ color: "white",marginRight: "10px", backgroundColor: "#f05050", fontWeight: 600 }} onClick={() => EndSelectedSessions()}>End Sessions </Button>
)}
    <Button variant="info" style={{ color: "white" }} onClick={() => getActiveUsers()}>
        <FaSyncAlt style={{ marginRight: "5px" }} title="Refresh" />
        {/* Refresh */}
    </Button>
    </div>
</div>

          <Table striped bordered hover responsive>
            <thead>
              <tr>
              <th style={{ textAlign: "center", verticalAlign: "middle" }}>select</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Player ID</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Email</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Mobile</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Tokens Earned</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Monthly Earning Cap</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Monthly Remaining Cap</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Boosted Monthly Cap</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Device Id</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Warned</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Disabled</th>
                <th style={{ textAlign: "center", verticalAlign: "middle" }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {currentActive.map((user) => (
                <tr key={user.id} onClick={()=> handleRowClick(user.id)}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(user.id)}
                      //onChange={() => toggleRowSelection(user.id)}
                      readOnly
                    />
                  </td>
                  {/* Table cells */}
                  <td>{user.id}</td>
                  <td>{user.email || "N/A"}</td>
                  <td>{user.mobile || "N/A"}</td>
                  <td>{user.goldFlake_earned}</td>
                  <td>{user.monthly_earning_cap}</td>
                  <td>{user.monthly_remaining_cap}</td>
                  <td>{user.boosted_monthly_cap}</td>
                  <td>{user.deviceId || "-"}</td>
                  <td>
                    <Badge bg={user.warning ? "danger" : "success"}>{user.warning ? "true" : "false"}</Badge>
                  </td>
                  <td>
                    <Badge bg={user.accountDisable ? "danger" : "success"}>{user.accountDisable ? "true" : "false"}</Badge>
                  </td>
                  <td>
                    <Button variant={"secondary"} style={{ color: "white", backgroundColor: "#f05050", fontWeight: 600 }} onClick={() => EndSession(user)}>End Session</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ActiveUsers;
