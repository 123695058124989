import React, { useState } from "react";
import { Row, Col, Breadcrumb, Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import * as Icon from "react-feather";
import GameList from './GameList'
import { useDispatch, useSelector } from "react-redux";
import { addGames, deleteGame, getStudioGames } from "../service/actions/gameActions"; 
import { useEffect } from "react";

const AddGame = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [gameName, setGameName] = useState("");
  const [gameUrl, setGameUrl] = useState("");

  // Toggle side bar menu
  const toggleSideMenu = (active) => {
    setSideMenu(active);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setGameName("");
    setGameUrl("");
  };

  const handleGameNameChange = (event) => {
    setGameName(event.target.value);
  };

  const handleGameUrlChange = (event) => {
    setGameUrl(event.target.value);
  };

  const dispatch = useDispatch()
  const addGame = () => {
    // Validate input before adding the game
    if (gameName && gameUrl) {
      // onAddGame({ name: gameName, url: gameUrl });
      dispatch(addGames({name:gameName,url:gameUrl}))
      closeModal();
    }
  };
  useEffect(()=>{
    dispatch(getStudioGames());

  },[])
  const games = useSelector(state=>state.game)
  const handleDeleteGame = (game) => {
    dispatch(deleteGame(game));
  };
  return (
    <div className="page-wrapper">
      {/* Navigation */}
      <Navigation onClick={toggleSideMenu} />
      {/* End Navigation */}

      <div className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}>
        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Add Game</h1>
            <Link to="/home" className="breadcrumb-item">
              Home
            </Link>
            <Breadcrumb.Item active>Add Game</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* End Breadcrumb */}

        {/* Game Form */}
        <div className="add-user-btn-container">
          <Button variant="primary" onClick={openModal}>
            Add Game
          </Button>
        </div>
        {/* End Game Form */}

        {/* Game List */}
        <GameList games={games} onDeleteGame={handleDeleteGame} />
        {/* End Game List */}

        {/* Game Modal */}
        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Game</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Game Name</Form.Label>
                <Form.Control type="text" value={gameName} onChange={handleGameNameChange} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Game URL</Form.Label>
                <Form.Control type="text" value={gameUrl} onChange={handleGameUrlChange} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={addGame}>
              Add Game
            </Button>
          </Modal.Footer>
        </Modal>
        {/* End Game Modal */}

        {/* Footer */}
        <div className="flex-grow-1"></div>
        <Footer />
        {/* End Footer */}
      </div>
    </div>
  );
};

export default AddGame;
