import { addReward, getAllRewards, getReward, removeReward, updateReward } from "../actionTypes/types";





const rewardReducer = (state=[],action)=>{
    switch (action.type) {
        case addReward:
          // Assuming action.payload contains a single game object
          return [...state, action.payload];
        case getReward:
          // Find and return the game with the given ID
          return state.find((reward) => reward.id === action.payload.id);
        case getAllRewards:
          // Assuming action.payload contains an array of reward objects
          return action.payload;
          case updateReward:
            console.log(action.payload,"THE ACTIUO ASIDB")
            return state.map((reward) =>
            reward.id === action.payload.id ? action.payload : reward
          );      
        case removeReward:
          // Remove the reward with the given ID from the state
          return state.filter((reward) => reward.id !== action.payload.id);
        default:
          return state;
      }
}

export default rewardReducer