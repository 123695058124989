import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { markGameFeatured, unmarkGameFeatured } from "../../service/actions/gameActions";


const GameFeatureButton = ({ game, onUnMarkGame, featuredGames, setFeaturedGames }) => {
  const [showModal, setShowModal] = useState(false);
  const [featureType, setFeatureType] = useState('freeTrial');
  const [currentGame, setCurrentGame] = useState(null);
  const dispatch = useDispatch();

  const handleShowModal = (game) => {
    setCurrentGame(game);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentGame(null);
  };

  const handleConfirm = () => {
    if (currentGame.featured === true) {
      onUnMarkGame(currentGame.id);
      setFeaturedGames(featuredGames.filter(id => id !== currentGame.id));
    } else {
      dispatch(markGameFeatured(currentGame.id, featureType));  
      setFeaturedGames([...featuredGames, currentGame.id]);
    }
    handleCloseModal();
  };

  return (
    <>
      <td style={{ width: '300px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        <Button
          onClick={() => handleShowModal(game)}
          variant={game.featured ? "danger" : "success"}
          style={{ width: '130px' }}
        >
          {game.featured ? "Unfeature Game" : "Feature Game"}
        </Button>
      </td>

      <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{currentGame?.featured ? "Unfeature Game" : "Feature Game"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentGame?.featured ? (
            <p>Are you sure you want to unfeature this game?</p>
          ) : (
            <>
              <p>Select the type of feature for this game:</p>
              <Form.Group>
                <Form.Label>Feature Type</Form.Label>
                <Form.Control
                  as="select"
                  value={featureType}
                  onChange={(e) => setFeatureType(e.target.value)}
                >
                  <option value="freeTrial">Free Trial</option>
                  <option value="paid">Paid</option>
                  
                </Form.Control>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            {currentGame?.featured ? "Unfeature" : "Feature"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GameFeatureButton;

