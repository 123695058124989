const { getAllKYC } = require("../actionTypes/types");



const kycReducer = (state=[], action)=>{

    switch(action.type){
        case getAllKYC:
            return action.payload;
        default:
            return state;
    }
}

export default kycReducer